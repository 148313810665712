import {
  createRoutesFromElements,
  Outlet,
  RouterProvider,
  useOutletContext,
  useParams,
  useRouteError,
} from 'react-router';
import { useContext, useCallback } from 'react';
import { createBrowserRouter, Route, useNavigate } from 'react-router-dom';
import { MainLayout } from './components/MainLayout';
// import { EdgeErrorLayout } from './components/EdgeErrorLayout.js'; Deprecated.  TODO: Delete this.
import { ProtectedRoute } from './components/ProtectedRoute';
import { PublicRoute } from './components/PublicRoute';

import { LoginView, action as loginAction } from './views/LoginView';
import { useEffect, useState } from 'react';
import { SignUpView } from './views/SignUpView'; 
import { RegisterView, action as registerAction } from './views/RegisterView';
import { ForgotPasswordView, action as forgotpasswordAction } from './views/ForgotPasswordView';
import { AboutView } from './views/AboutView'; 
import { UserView }  from './views/UserView';
import { GeoView }  from './views/GeoView';
import { SettingsView }  from './views/SettingsView';//, action as settingsAction }  from './views/SettingsView';
import { NotFoundView } from './views/NotFoundView';
import { CreateView } from './views/CreateView';//, action as createAction } from './views/CreateView';
import { FollowingView } from './views/FollowingView';
import { FollowersView } from './views/FollowersView';
import { FeedView } from './views/FeedView';
import { OtherUserView } from './views/OtherUserView';
import { ReportView, action as reportAction } from './views/ReportView';
import { logoutWithPg } from './data/rdsClient.js';
// import { PaypalView } from './views/PaypalView.js'; TODO: I think this is unused and should be removed or converted into documentation.
import { ChangePasswordView, action as changePasswordAction } from './views/ChangePasswordView';
import { BuyTokensView } from './views/BuyTokensView';
import { CashOutView } from './views/CashOutView';
import { BalanceView } from './views/BalanceView';
import { HashtagView } from './views/HashtagView';
import { ContestView } from './views/ContestView';
import { CampaignView } from './views/CampaignView';
import { SignedUpNotRegisteredView } from './views/SignedUpNotRegisteredView';
import { CaptchaFailedView } from './views/CaptchaFailedView';
import { NotificationsView } from './views/NotificationsView';
import { SearchResultView } from './views/SearchResultView.js';
import { ArticleView } from './views/ArticleView.js';


const ErrorView = () => {
  const error = useRouteError();

  return (
    <div style={{ backgroundColor: 'white' }}>
      Error View
      <p>{error.message}</p>
      <p>Please email us at singlepaynews@gmail.com</p>
    </div>
  );
};


// SessionProvider component definition...
const SessionProvider = () => {
  const [data, setData] = useState(() => {
    const storedSession = localStorage.getItem('session');
    const parsedSession = storedSession ? JSON.parse(storedSession) : null;
    return parsedSession?.data ? parsedSession.data : null;
  });

  // State for unchecked notifications
  const [uncheckedNotifs, setUncheckedNotifs] = useState(0);

  // Update unchecked notifications count
  const update_uncheckedNotifs = (new_uncheckedNotifs) => {
    setUncheckedNotifs(new_uncheckedNotifs);
  };

  // Update balance in the session
  const updateBalance = (newBalance) => {
    if (data?.session?.userPrivate) {
      const updatedSession = {
        ...data,
        session: {
          ...data.session,
          userPrivate: {
            ...data.session.userPrivate,
            balance: newBalance,
          },
        },
      };

      setData(updatedSession);
      localStorage.setItem('session', JSON.stringify({ data: updatedSession }));
    } else {
      console.error('Cannot update balance. User session is not properly defined.');
    }
  };

  // Log in and update session data
  const login = (newSessionData) => {
    if (newSessionData?.data) {
      // Store new session data in localStorage and state
      localStorage.setItem('session', JSON.stringify(newSessionData));
      setData(newSessionData.data);

      console.log('session updated');
      console.log('newSession balance:', newSessionData?.data?.session?.userPrivate?.balance);
    } else {
      console.error('Invalid session data during login.');
    }
  };

  // Logout function: Clears session data
  const logout = async () => {
    const logoutResp = await logoutWithPg();
    console.log(logoutResp);

    // Clear session data from localStorage and state
    localStorage.removeItem('session');
    setData(null);

    console.log('session cleared');
  };

  // Ensure that session data remains consistent
  useEffect(() => {
    const storedSession = localStorage.getItem('session');
    if (!data && storedSession) {
      const parsedSession = JSON.parse(storedSession);
      setData(parsedSession?.data || null);
    }
  }, [data]);

  // Return the context with all necessary values for child components
  return (
    <Outlet
      context={{
        data,
        login,
        logout,
        updateBalance,
        uncheckedNotifs,
        update_uncheckedNotifs,
      }}
    />
  );
};


// export default SessionProvider;



const router = createBrowserRouter(
  createRoutesFromElements([
    // SessionProvider can maybe be deleted/replaced with AuthContext
    // todo: understand the Session vs AuthContext details; see MainLayout
    <Route element={<SessionProvider />} errorElement={<ErrorView />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<FeedView />} />
          {/* To link to a particular article, we have to be able to show it at the top of a feed. */}
          <Route path="/:article" element={<FeedView />} />
          {/* A page for web_anon to buy articles, is linked to via more-$0.50 button */}
          <Route path="/article/:articleIdUrlParam" element={<ArticleView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/sunr" element={<SignedUpNotRegisteredView />} />
          <Route path="/captchafailed" element={<CaptchaFailedView />} />
          <Route path="/contest" element={<ContestView />} />
          <Route path="/campaigns" element={<CampaignView />} />
          {/* Todo: is this used? */}
          {/* <Route path="/pay" element={<PaypalView />} /> */}
          {/* Ideally the web_anon payment flow is based on a /component that displays inline in FeedView*/}
          <Route
            path="/buytokens"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<BuyTokensView />} />}
              />
            }
          />
          <Route
            path="/cashout"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<CashOutView />} />}
              />
            }
          />
          <Route
            path="/balance"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<BalanceView />} />}
              />
            }
          />
          {/* Login/Signup: */}
          <Route
            path="/login"
            element={<PublicRoute element={<LoginView />} />}
            action={loginAction}
          />
          
          <Route path="/signup" element={<PublicRoute element={<SignUpView />} />} />
          
          {/* Register/Change Password: */}
          <Route
            path="/register/"
            element={<PublicRoute element={<RegisterView />} />}
            action={registerAction}
          />
          <Route
            path="/register/:email/:code/:initial_password"
            element={<PublicRoute element={<RegisterView />} />}
            action={registerAction}
          />
          <Route
            path="/forgot-password"
            element={<PublicRoute element={<ForgotPasswordView />} />}
            action={forgotpasswordAction}
          />
          <Route
            path="/change-password/:email/:code"
            element={<PublicRoute element={<ChangePasswordView />} />}
            action={changePasswordAction}
          />
          {/* unused; todo: use it lol */}
          <Route
            path="/geo"
            element={<PublicRoute element={<GeoView />} />}
          />
          {/* Stuff you *do* follow: */}
          <Route
            path="/account"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<UserView />} />}
              />
            }
          />
          <Route
            path="/account/following"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<FollowingView />} />}
              />
            }
          />
          <Route
            path="/account/followers"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<FollowersView />} />}
              />
            }
          />
          {/* Stuff you can follow: */}
          <Route 
            path="/user/:id" 
            element={<PublicRoute element={<OtherUserView />}/>} 
            />
          <Route
              path="/hashtag"
              element={<PublicRoute element={<HashtagView />} />}
            />
          <Route
              path="/hashtag/:text"
              element={<PublicRoute element={<HashtagView />} />}
            />
          <Route
              path="/hashtag/:text/:id"
              element={<PublicRoute element={<HashtagView />} />}
            />
          {/* <Route path="/search" element={<PublicRoute element={<SearchResultView />} />} /> */}
          <Route
              path="/search/:query"
              element={<PublicRoute element={<SearchResultView />} />}
            />
          {/* Stuff you can post: */}
          <Route
            path="/settings"
            element={<ProtectedRoute element={<SettingsView />} />}
          />
          <Route 
            path="/create" 
            element={<PublicRoute element={<CreateView />}/>}
          />
          <Route
            path="/report/:item_type/:item_id"
            element={<ProtectedRoute element={<ReportView />} />}
            action={reportAction}
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<NotificationsView />} />}
              />
            }
          />
        </Route> {/* End MainLayout */}
        {/* 404: */}
        <Route path="*" element={<NotFoundView />} />
    </Route>,
  ]),
);



export const App = () => {
  console.log('Router Setup:', router);

  return (
    <div>
        <RouterProvider router={router}>
          <SessionProvider>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </SessionProvider>
        </RouterProvider>
      {/* )} */}
    </div>
  )
};
