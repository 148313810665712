import { useState } from "react";
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';


export const AboutView = () => {
    const [activeIndex, setActiveIndex] = useState(9); //start with an unused value

    const handleAccordionClick = (index) => { 
        console.log('activeIndex before: ',activeIndex)
        setActiveIndex(index);
        console.log('activeIndex after: ',activeIndex)
    };
    
    return(
        <>
        <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
            <Container text>
            <Header as='h3' inverted></Header>  
            <Accordion inverted>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() =>handleAccordionClick(0)}
            >
            <Icon name='dropdown'/>
            Founding Principles
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <>
            <p>	Single Pay News (SPN) was founded with one goal: to help journalists build and monetize an audience for their work. All of our policies designed to serve that goal. Our policies are binding on all users of, and visitors to, our website. If you agree that honest journalism at the local and national level is valuable, we encourage you to read our policies. Not simply so that you will understand the rules, but so that you can become an active participant in our mission to support journalism.	</p>
            <p>	Just like everyone else, journalists must be paid for their work or they can’t afford to do it. It is really that simple. There is no shortage of journalists who will happily keep an eye on things if they are able to be paid a fair price for their work. Single Pay News' goal is to provide both the audience and the compensation for the time and energy necessary to create quality journalism.	</p>
            <p>	It is no secret that over the past two decades thousands of local newspapers have folded up operations across the United States. Thousands more have been snapped up by hedge funds and are now being operated with skeleton staffs. The end result is that roughly half of the United States now exists in what have been referred to as “news deserts” where local and national politics, events, sports, and business are not covered by independent reporting.	</p>
            <p>	While numerous non-profit models are being developed in an effort to fill that need (along side of the declining subscription based, for-profit newspapers), we believe that the market has demonstrated that from the journalist’s perspective, these models are flawed. We believe that what is missing is a model that does not require either the long term commitment of a subscription from the consumer’s perspective or the long term commitment of a benefactor from the non-profit’s perspective. We believe that journalists must be empowered to build their own brand, consisting of well informed readers who know and trust these journalists and are willing to pay them for their work.	</p>
            <p>	Substack and other platforms provide a means for journalists to build a base of paying subscribers. Single Pay News seeks to augment those subscriber models, to provide additional revenue and exposure for these journalists. To do so, we have set up Single Pay News (SPN) as an easy and convenient way for journalists to sell their work without requiring a subscription at a nominal price. Trust- worthy journalists using the SPN platform to publish quality work will expose their work to paying customers, who will provide revenue in the short term, and grow their base of paid subscribers in the long term.	</p>

            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={() =>handleAccordionClick(1)}
            >
            <Icon name='dropdown'/>
            How Users Can Help
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <>
            <p>	If you are here on Single Pay News, you likely share our desire to make local journalism financially viable. Obviously, you can help by purchasing articles from your favorite authors. You can also help by purchasing those same articles for your friends and family, and/or by tipping an author for an article you found especially valuable. But there are also equally important ways to support the journalists that don’t cost any money.	</p>
            <p>	Promotion is King. You can help SPN journalists sell their work by sending links to their Paid Posts via email, text, or whatever means you use to share information on the internet. Just click the share icon to copy the Post’s URL, and you can share it anywhere on the internet. Exposing the Paid Posts to a wider audience helps our authors sell their work, and it grows the user base of SPN for future articles.	</p>
            <p>	Also, post great content on SPN. All social media companies depend on their users to provide content that drives traffic to the website. SPN is no different. By making high quality free posts and comments, you help drive that traffic. The more witty, insightful, and articulate the content you post, the greater the quality and quantity of users who are attracted to spend time reading SPN, who in turn are exposed to the Paid Posts of SPN journalists. That is how we save local journalism.	</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={() =>handleAccordionClick(2)}
            >
            <Icon name='dropdown'/>
            Your Account Balance
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
            <>
            <p>	To buy Paid Posts, you need to fund your User Account with Tokens. The amount shown in your Account Balance is shown as Tokens, and it is not money. Tokens are purchased from SPN at the rate of 1 token per $0.01. Tokens can only be used to purchase Paid Posts on Single Pay News. Initially, your Account Balance is zero. Your Account Balance is then funded by you making a purchase using your credit card or Pay Pal. The cost of the transaction plus $1 is deducted from your purchase to determine your Account Balance. So, for example, if you funded your account with a $10.00 purchase using Pay Pal and the transaction fee to Pay Pal was $0.79, your Account Balance would then show 821 Tokens. $0.79 was deducted to pay PayPal’s transaction fee, and another $1 was deducted to pay SPN. The Tokens in your Account Balance are not refundable or transferrable. The Tokens can only be used to purchase Paid Posts on SPN. Once you complete a transaction to purchase Tokens, the transaction fee is paid to the credit card company or Pay Pal, $1 is paid to SPN, and the remainder is converted into your Account Balance, which, again, is not money. It is Tokens that can only be used to purchase Paid Posts on Single Pay News. If you engage in conduct that causes you to be banned from SPN, you may forfeit any Tokens in your Account Balance to SPN.	</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={() =>handleAccordionClick(3)}
            >
            <Icon name='dropdown'/>
            Content Moderation
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
            <>
            <p>Our goal is to foster a community that pays journalists for their work. Content posted by users that
helps us achieve this goal is sincerely and deeply appreciated. Content that detracts from this goal will
be removed. We will and must moderate content on our website. Content that helps us build a
community of users who care about and financially support local journalism will remain. Content that
we determine, in our sole and exclusive discretion, that detracts from building a community that buys
local journalism will be removed, and the authors of that content may be banned.</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={() =>handleAccordionClick(4)}
            >
            <Icon name='dropdown'/>
            Paid Posts and Free Posts
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
            <>
            <p>	There are three ways to post content on SPN: Free Posts, Paid Posts and comments. SPN Users can see the comments for both Paid Posts and Free Posts simply by clicking the comment icon at the bottom of the post. SPN Users can see the headlines and comments for both Paid Posts and Free Posts. If a SPN User wants to see the remainder of a Free Post, the User simply clicks on the “more” button at the bottom of the Free Post, and the remainder of the Free Post will appear. If a SPN User wants to see the remainder of a Paid Post, the User simply clicks on the “more 50” button at the bottom of the Paid Post. If the User has at least 50 tokens in their Account Balance, their Account Balance will be reduced by 50 tokens, and the remainder of the Paid Post will appear. If the User does not have at least 50 tokens in their Account Balance, the User will be directed to a page asking them to purchase tokens for their Account Balance.	</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={() =>handleAccordionClick(5)}
            >
            <Icon name='dropdown'/>
            Paid Post Revenue
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
            <>
            <p>	Journalists who publish Paid Posts earn money when Users click on the “more 50” button at the bottom of the Paid Post. Single Pay News has a standard charge of 50 tokens per Paid Post. When a User clicks on the “more 50” button at the bottom of the Paid Post, if the User has at least 50 tokens in their Account Balance, their Account Balance will be deducted by 50. $0.20 will then be credited to the Journalist’s Earnings Balance, and $0.05 will be credited to Single Pay News. Coming soon SPN will add a feature that will allow Users to purchase a Paid Post for a friend by entering that friend’s email address and clicking on a button that will recite “Give this article to a friend for 50.” When a User clicks on the “Give this article to a friend for 50” button and enters an email at the prompt, if the User has at least 50 tokens in their Account Balance, their Account Balance will be deducted by 50 tokens and the Paid Post will be sent to the email entered into the box. $0.20 will then be credited to the Journalist’s Earnings Balance, and $0.05 will be credited to Single Pay News.	</p>
            <p>	Also coming soon, SPN will add yet another feature that will allow Users to provide an additional “tip” to a Journalist. SPN will keep none of the tip money.	</p>
            <p>	On the last day of each month, every Journalist with a positive Earnings Balance exceeding one hundred dollars ($100) will be paid that Earnings Balance in its entirety. If, for whatever reason, a Journalist is banned or suspended from the SPN website, their Earnings Balance will be paid to that Journalist in its entirety, less any amounts retained by SPN that SPN determines, in SPN’s sole and exclusive judgment, that are reasonably anticipated to be necessary to resolve disputes or other litigation related to the conduct that caused the Journalist to be banned.	</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 6}
                index={6}
                onClick={() =>handleAccordionClick(6)}
            >
            <Icon name='dropdown'/>
            Paid Post Moderation
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
            <>
            <p>	In the long run, the Journalists who are earning their living through SPN will have the greatest stake in insuring that the Paid Posts published on SPN meet their journalistic standards, because poor quality journalism will reflect poorly on every Journalist who publishes on the SPN website. For that reason, as soon as practicable, SPN will transfer the responsibility for ensuring Paid Posts on SPN meet the highest journalistic standards to the Journalists themselves.	</p>
            <p>	SPN anticipates that this transfer will occur when there are at least a dozen Journalists who are earning at least $1,000 per month publishing Paid Posts on SPN, and that the transfer will initially be to that same group of Journalists. SPN will then provide a recommendation for a set of standards and mechanisms to police and adjudicate alleged violations of the standards to that group of Journalists, but ultimately, the standards and policing mechanisms will be decided by SPN’s Journalist community. SPN is confident that the Journalists using SPN will create workable and successful policies, standards, and enforcement mechanisms to ensure that Paid Posts on SPN are of the highest journalistic standards, since the Journalists responsible for those policies, standards and enforcement mechanisms will have their own livelihoods at stake if those standards are not upheld and enforced.	</p>
            <p>	Until that transfer happens, SPN will be acting as the moderator for Paid Posts. For the time being, we will refer to this as the “SPN Standard.” Under the SPN Standard, Paid Posts must be sourced, must be accurate, must be true, and must not be plagiarized. SPN will have the sole discretion to determine if any and all Paid Post do not meet the SPN Standard. If, in SPN’s sole discretion, SPN determines that a Paid Post does not meet the SPN Standard, SPN will have the right and authority to take immediate action, up to and including removing all Paid Posts authored by the offending Journalist (including Paid Posts that did not violate the SPN Standard) and permanently banning the Journalist from the SPN website. SPN’s determination shall be final and non-appealable. The Journalist hereby waives any and all rights to pursue any cause of action in any Court against SPN related to an alleged or actual violation of the SPN Standard.	</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            <Accordion.Title
                active={activeIndex === 7}
                index={7}
                onClick={() =>handleAccordionClick(7)}
            >
            <Icon name='dropdown'/>
            Contact
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>
            <>
            <p>Send all inquiries via email to singlepaynews@gmail.com</p>
            </>
            </Accordion.Content>

{/* ------------------------------------------------------------------------ */}

            </Accordion>
        </Container>
        </div>
        </>
    )
}