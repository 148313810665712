import {
  Container, Avatar, Typography, Card, CardHeader,
  CardContent, CardMedia, IconButton, Box, Button, useMediaQuery, Divider,
  stepConnectorClasses
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'; // todo: change thumbup icon state when article liked
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { useNavigation, Outlet, useOutletContext } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getPgData, postPgData, patchPgData } from '../data/rdsClient';
import { useParams } from 'react-router-dom';
import { useId, useRef } from 'react';

// Import a markdown parser (Markdown-it is a popular choice)
import MarkdownIt from "markdown-it";
import markdownItMark from 'markdown-it-mark'; // underlining isn't a standard markdown feature
import { set } from 'lodash';

// Instantiate mdParser
const mdParser = new MarkdownIt({
  html: true, // Enable HTML tags in source
  linkify: true, // Autoconvert URL-like text to links
  typographer: true, // Enable some nice transformations (like (c) -> ©)
}).use(markdownItMark);

// add a function for parsing the article timestamps
const { DateTime } = require('luxon');


export const FeedView = () => {
  const { article } = useParams();
  const navigation = useNavigation();
  const navigate = useNavigate();
  
  //Introduce State for Error Handling
  // enhance the handleError function to handle the special case where the error message is related to buying tokens.
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorPostId, sererrorPostId] = useState(null);
  const handleError = (id, message) => { // Update handleError to Use navigate()
    if (message.includes('buy more tokens to read')) {
      sererrorPostId(id);
      setErrorMessage(
        <>
          {message.replace('buy more tokens to read', '')}
          <Button onClick={() => navigate('/buytokens')}>buy more tokens to read</Button>
        </>
      );
    } else {
      sererrorPostId(id);
      setErrorMessage(message);
    }
  };
  const resetError = () => { 
    sererrorPostId(null);
    setErrorMessage(null); 
  };
  // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => { setSuccessMessage(message); };
  const resetSuccess = () => { setSuccessMessage(null); };

  const commentTextAreaId = useId(); // hook for CommentForm
  const isLoading = // set isLoading for comment form
    navigation.state === 'loading' || navigation.state === 'submitting';
  //Workflow for all subabase quieres
  const session = useOutletContext(null); //Session store user token
  const [articles, setArticles] = useState([]); //Gets article for user from RDS
  // displaying comments
  const [comments, setComments] = useState([]); // need to fetch comments when displaying
  const [comments_for_article, setCommentsForArticle] = useState([]); // which article in the DOM?
  // displaying article content
  const [content_for_article, setContentForArticle] = useState(null); // which article in the DOM?
  const [web_anon_article_purchase, setweb_anon_article_purchase] = useState(false); // Did a web_anon try to buy an article?
  // buying articles and dealing with balance/artices_bought/etc.
  console.log('FeedView, session=', session)
  console.log('articles= ', articles)

  // rerendering thumbs on new likes
  const [articles_liked, setArticles_liked] = useState()
  // add a function for parsing the article timestamps
  function parseTimestamp(timestamp) {
    const dt = DateTime.fromISO(timestamp, { zone: 'utc' });
    const now = DateTime.utc();
    const diffInDays = now.diff(dt, 'days').toObject().days;
    if (diffInDays >= 1) {
      return dt.toFormat('LL/dd/yyyy');
    } else {
      const dayOfWeek = dt.toFormat('EEEE');
      const timeOfDay = dt.toFormat('hh:mm a');
      const timeZoneName = dt.toFormat('ZZZZ');
      return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;
    }
  }

  // todo: display hashtags as links in blurbs
  function parseBlurb(blurb) {
    var regex = /#\w+(?=\s|$)/g;
    var hashtagsWithHash = blurb.match(regex) || [];
    var hashtags = hashtagsWithHash.map(hashtagWithHash => hashtagWithHash.substring(1));
    var parsedBlurb = blurb;
    hashtags.slice(0, 5).forEach(hashtag => {
      const hashtagLink = `<a href="/hashtag/${hashtag}">#${hashtag}</a>`;
      parsedBlurb = parsedBlurb.replace(new RegExp(`#${hashtag}\\b`, 'g'), hashtagLink);
    });
    return parsedBlurb;
  }

  async function fetchArticles() {
    // What this code does:
    //  If article is defined:
    //  Fetches the specific article and adds it to the arr.
    //  Fetches the rest of the articles (excluding the specific article) and concatenates them after the specific article.
    // If article is not defined:
    //  Fetches Dad's article (id = 1522) first and adds it to the top of the arr.
    //  Fetches a general list of articles and concatenates them after Dad's article.
    // Concatenation:
    //  In both cases, the final array is set using setArticles(arr.concat(...)), ensuring that Dad's article or the specific article comes first.

    let arr = [];

    try {
      if (article) {
        // Case 1: article is defined
        const topArticle = await getPgData(`/articlefresh?id=eq.${article}`);
        if (topArticle.data?.[0]?.id) {
          arr.push(topArticle.data[0]);
        }

        const restOfArticles = await getPgData(`/articlefresh?limit=100&order=created.desc&id=not.in.(${article})`);

        if (Array.isArray(restOfArticles.data)) {
          setArticles(arr.concat(restOfArticles.data)); // Add general articles after Dad's article
        } else {
          console.error('restOfArticles data is not an array:', restOfArticles.data);
        }
      } else {
        // Case 2: article is not defined
        // Fetch Dad's article first
        const dadsArticle = await getPgData(`/articlefresh?id=eq.1522`);
        if (dadsArticle.data?.[0]?.id) {
          arr.push(dadsArticle.data[0]); // Add Dad's article first
        }

        // Fetch general list of articles
        const restOfArticles = await getPgData(`/articlefresh?limit=100&order=created.desc`);

        if (Array.isArray(restOfArticles.data)) {
          setArticles(arr.concat(restOfArticles.data)); // Add general articles after Dad's article
        } else {
          console.error('restOfArticles data is not an array:', restOfArticles.data);
        }
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      setErrorMessage('Failed to load articles.');
    }
  }


  // Asynchronous function to fetch notifications
  async function fetchNotifications() {
    console.log('fetchNotifications');
    let token = '';
    if (session?.data?.session?.userPublic?.id) { // will fail when not logged in
      console.log('fetchNotifications session=', session) // may not be signed in
      token = session ? session.data.session.access_token : null // todo: examine session param for default value case
    } else {
      token = ""
      console.log('no session.data, not fetchNotifications')
    }
    try {
      // getPgData notifications where is = session...id 
      const { data: notificationsData, error: notificationsError } = await getPgData(`/notifications?owner_uid=eq.${session.data.session.userPublic.id}`, {}, session);// todo: examine session param for default value case

      if (notificationsError) {
        console.error('Error:', notificationsError);
        console.log(notificationsError.message);
        return { error: notificationsError.message };
      }

      console.log('getPgData - fetchNotifications:', notificationsData);
      // setNotifs(notificationsData); // setNotifs to the full array

      // Filter unchecked notifications and update count
      const uncheckedNotifications = notificationsData.filter(notification => !notification.is_checked);
      console.log('uncheckedNotifications: ', uncheckedNotifications)
      console.log('uncheckedNotifications.length: ', uncheckedNotifications.length)
      // update state var in session context
      session.update_uncheckedNotifs(uncheckedNotifications.length); // Update context
    } catch (error) {
      console.error('fetchNotifications error:', error);
      return { error: error.message };
    }
  }


  async function fetchComments(article_id) {
    setCommentsForArticle(article_id) // which article in the DOM?
    try {
      const response = await getPgData(`/comment?parent_article=eq.${article_id}&order=num_likes.desc,parent_comment.nullsfirst`); // todo: examine session param for default value case
      if (Array.isArray(response.data)) {
        setComments(response.data); // Set the response data if it's an array
        console.log('FeedView comments query: ', response.data);
      } else {
        console.error('Response data is not an array:', response.data);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  async function readArticle(post, session) {
    console.log('readArticle post: ', post);
    console.log('readArticle session: ', session);
    if (!session?.data) {
      if (post.is_paid) {
        console.log('User not signed in, redirect to ArticleView');
        navigate(`/article/${post.id}`);
        return;
      } else {
        if (content_for_article !== post.id) {
          setweb_anon_article_purchase(false);
          setContentForArticle(post.id);
        }
        return;
      }
    }
  
    if (post.is_paid) {
      const sessionData = await validateSession(session, post);
      if (!sessionData) return;
  
      const { userPrivate, userPublic, token } = sessionData;
      if (await hasBoughtArticle(userPrivate, post)) {
        if (content_for_article !== post.id) {
          setweb_anon_article_purchase(false);
          setContentForArticle(post.id);
        }
        return;
      }
  
      if (userPrivate.id !== post.user_author) {
        const canBuy = await validateUserBalance(userPrivate);
        if (canBuy) {
          await purchaseArticle(post, sessionData);
        } else {
          handleError(post.id, 'Out of tokens, buy more tokens to read');
        }
      } else {
        if (content_for_article !== post.id) {
          setweb_anon_article_purchase(false);
          setContentForArticle(post.id);
        }
      }
    } else {
      handleFreeArticle(post, session);
    }
  }
  

  async function tipAuthor(post, session) {
    console.log('tipAuthor post: ', post);
    console.log('tipAuthor session: ', session);

    if (!session.data) { // If the user is not signed in 
      console.log('User not signed in, navigate to signup');
      navigate(`/signup`);
      return;
    } 

    if (session.data.session.userPrivate.balance < 50) { // if the user is signed in but doesn't have enough tokens
      handleError(post.id, "Must have 50 tokens to Tip")
    } else { // user is signed in and has 50 tokens
      const sessionData = await validateSession(session, post);
      if (!sessionData) return;
      const { userPrivate, userPublic, token } = sessionData;

      if (userPrivate.id !== post.user_author) { // can't tip yourself
        const canBuy = await validateUserBalance(userPrivate);
        if (canBuy) {
          await purchaseArticle(post, sessionData);
          window.alert('Tipped $0.50, thank you for supporting Independent Journalism!')
        } else {
          handleError(post.id, 'Not enough tokens');
        }
      } else {
        console.log('User is the author of the article');
        handleError(post.id, 'User is the author of the article');
        setweb_anon_article_purchase(false);
      }
    }
  }

  // Validate session and return session data if valid
  async function validateSession(session, post) {
    //TODO: this is gonna throw cannot access prop of undefined I just know it.
    if (session && session.data && session.data.session.access_token && session.data.session.userPublic) {
      const userPrivate = session.data.session.userPrivate;
      if (userPrivate && userPrivate.id) {
        return { userPrivate, userPublic: session.data.session.userPublic, token: session.data.session.access_token };
      }
    }
    // jeez i dunno something terrible has happened you should probably burn this page to the ground and start over
    console.log('jeez i dunno something terrible has happened you should probably burn this page to the ground and start over');
    return null;
  }

  // Check if the user has already bought the article
  async function hasBoughtArticle(userPrivate, post) {
    if (userPrivate.articles_bought_articleid == null) {
      userPrivate.articles_bought_articleid = [];
    }
    return userPrivate.articles_bought_articleid.includes(post.id);
  }

  // Check if user has enough tokens to buy the article
  async function validateUserBalance(userPrivate) {
    if (userPrivate.balance >= 50) {
      return true;
    } else {
      console.log('User does not have enough tokens:', userPrivate.balance);
      return false;
    }
  }

  async function purchaseArticle(post, sessionData) {
    console.log('purchaseArticle sessionData: ', sessionData);
    const { userPrivate, userPublic } = sessionData;
  
    const articleboughtby_Data = {
      purchasing_user_id: userPublic.id,
      article_id: post.id,
      when: 'NOW()',
      uid_of_article_author: post.user_author,
      purchasing_user_name: userPublic.name,
      author_name: post.author_name,
      article_title: post.title,
    };
  
    try {
      const response = await postPgData(`/articleboughtby`, articleboughtby_Data, sessionData.token);
      if (response.data) {
        // Update article content and notify the author
        if (content_for_article !== post.id) {
          setContentForArticle(post.id); // Expose article content
        }
  
        setweb_anon_article_purchase(false);
        await notifyAuthor(post, sessionData, 'bought');
  
        // Fetch updated session after purchase
        const updatedSession = await updateSession(sessionData);
  
        // Update the balance explicitly
        if (updatedSession && updatedSession.userPrivate.balance !== session.data.session.userPrivate.balance) {
          session.updateBalance(updatedSession.userPrivate.balance);
          console.log('Balance updated in session:', updatedSession.userPrivate.balance);
        } else {
          handleError(post.id, 'Failed to update balance after purchase.');
        }
      } else {
        handleError(post.id, 'Something went wrong; try signing in again?');
      }
    } catch (error) {
      handleError(post.id, error.message || 'Failed to purchase the article.');
    }
  }
  
  
  
  async function updateSession(sessionData) {
    console.log('updateSession sessionData: ', sessionData);
  
    try {
      const { data: userPrivateData, error: userPrivateError } = await getPgData(`/userprivate?id=eq.${sessionData.userPublic.id}`, {}, sessionData.token);
      const { data: userPublicData, error: userPublicError } = await getPgData(`/userpublic?id=eq.${sessionData.userPublic.id}`, {}, sessionData.token);
  
      if (userPrivateError || userPublicError) {
        console.error("Failed to update session:", userPrivateError || userPublicError);
        return null;  // Return null if update fails
      }
  
      if (!userPrivateData || !userPublicData) {
        console.error("User data missing. Cannot update session.");
        return null;
      }
  
      // Update the session with refreshed data
      let newSession = {
        ...sessionData,
        userPrivate: userPrivateData[0],
        userPublic: userPublicData[0],
      };
  
      // Store the updated session in localStorage
      localStorage.setItem('session', JSON.stringify({ data: newSession }));
  
      // Update the OutletContext session
      session.login({ data: newSession });
  
      console.log('Session updated successfully');
      return newSession;  // Return the updated session for further use
    } catch (error) {
      console.error("Error during session update: ", error);
      handleError(0, "Session update failed.");
      return null;
    }
  }
  
  

  // Handle free articles
  async function handleFreeArticle(post, session) {
    setweb_anon_article_purchase(false);
    if (content_for_article !== post.id) {
      setContentForArticle(post.id); // Expose article content only if not already set
    }
    await notifyAuthor(post, session, 'read');
  }

  // Send notification to the article's author
  async function notifyAuthor(post, sessionData, action) {
    console.log('notifyAuthor sessionData: ', sessionData)
    const { userPrivate, userPublic, token } = sessionData;

    // Declare the notification object outside of the if-else block
    let notification;
    // bugfix: notifyAuthor needs to be able to handle web_anon, in which case userPublic is undefined
    if (userPublic) { // if signed in
      notification = {
        trigger_uid: userPublic.id,
        trigger_uname: userPublic.name,
        action,
        subject_aid_uid: post.id,
        subject_title_name: post.title,
        owner_uid: post.user_author
      };
    } else { // if web_anon
      notification = {
        trigger_uid: null,
        trigger_uname: 'Someone',
        action,
        subject_aid_uid: post.id,
        subject_title_name: post.title,
        owner_uid: post.user_author
      };
    }

    const { error } = await postPgData(`/notifications`, notification, sessionData.token);
    if (error) console.error('Error:', error);
  }

  // Handle auto-follow for authors and hashtags
  async function handleAutoFollow(post, sessionData) {
    console.log('handleAutoFollow sessionData: ', sessionData)
    const { userPrivate, userPublic, token } = sessionData;

    if (!userPublic.following_ids.includes(post.user_author)) {
      await postPgData('/rpc/follow_user', { p_follower_uid: userPublic.id, p_uid_followed: post.user_author }, sessionData.token);
    }

    // React minified err 31 when buying an article that's never been bought on iphone    
    for (const tag of post.tag_names) {
      if (!userPublic.hashtags_following_text.includes(tag)) {
        await postPgData('/rpc/follow_hashtag', { p_user_id: userPublic.id, p_hashtag_text: tag }, sessionData.token);
      }
    }
    if (userPrivate.id !== post.user_author) {
      const canBuy = await validateUserBalance(userPrivate);
      if (canBuy) {
        await purchaseArticle(post, sessionData);
        setweb_anon_article_purchase(false);
        if (content_for_article !== post.id) {
          setContentForArticle(post.id); // Expose article content only if not already set
        }
      } else {
        handleError(post.id, 'Out of tokens, buy more tokens to read');

      }
    }
  }

  // todo: lol add unlike article
  async function handleArticleLike(articleId, articleTitle, article_authorId) {
    // num_likes on article is incremented by on insert trigger against spn.likes table
    console.log('handleArticleLike');
    let token = '';
    let userId = '';
    if (session?.data?.session?.userPublic?.id) {
      console.log('handleArticleLike session=', session)
      token = session.data.session.access_token
      userId = session.data.user.id
    } else {
      handleError(articleId, 'Are you logged in?')
      return 'not logged in'
    }

    try {
      // num_likes on article is incremented by on insert trigger against spn.likes table
      const { data: likesData, error: likesError } = await getPgData(`/likes?thing_liked=eq.${articleId}&user_liking=eq.${userId}`, {}, session);

      if (likesError) {
        console.error('Error:', likesError);
        console.log(likesError.message);
        return { error: likesError.message };
      }

      console.log('getPgData - Likes:', likesData);

      //  Now use the data from the first call in the second call
      // if not in likes postPgData likes
      if (likesData.length === 0) {
        const { data: postLikeData, error: postLikeError } = await postPgData(
          `/likes`,
          {
            thing_liked: articleId,
            user_liking: userId,
            type_of_thing_liked: 'article'
          },
          session
        );

        if (postLikeError) {
          console.error('Error:', postLikeError);
          console.log(postLikeError.message);
          return { error: postLikeError.message };
        }

        console.log('postPgData - Likes:', postLikeData);
        const { data: userprivateLikes, error: userprivateLikesError } = await getPgData(
          `/userprivate?id=eq.${userId}&select=articles_liked`,
          {},
          session
        );

        if (userprivateLikesError) {
          console.error('Error:', userprivateLikesError);
          console.log(userprivateLikesError.message);
          return { error: userprivateLikesError.message };
        }

        console.log('getPgData - userprivate Likes:', userprivateLikes);

        let updated_userprivate_articles_liked = userprivateLikes[0].articles_liked;
        if (updated_userprivate_articles_liked === null) { // what if this is their first like?
          updated_userprivate_articles_liked = [];
        }
        updated_userprivate_articles_liked.push(articleId);
        const { data: userLikesPatch, error: userLikesPatchError } = await patchPgData(
          `/userprivate?id=eq.${userId}`,
          { articles_liked: updated_userprivate_articles_liked },
          session
        );

        if (userLikesPatchError) {
          console.error('Error:', userLikesPatchError);
          console.log(userLikesPatchError.message);
          return { error: userLikesPatchError.message };
        }

        console.log('patchPgData - userprivate Likes:', userLikesPatch);

        // update setState
        setArticles_liked(updated_userprivate_articles_liked);

        // add to notifications table
        // notifications are default false
        console.log('postPgData - notifications:');
        const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
          `/notifications`,
          {
            trigger_uid: session.data.user.id,
            trigger_uname: session.data.user.username,
            action: 'liked', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
            subject_aid_uid: articleId,
            subject_title_name: articleTitle, // had to add this as a input to handleArticleLike() function
            owner_uid: article_authorId // had to add this as a input to handleArticleLike() function
          },
          session
        );

        if (postPgNotificationError) {
          console.error('Error:', postPgNotificationError);
          console.log(postPgNotificationError.message);
          return { error: postPgNotificationError.message };
        }

        console.log('postPgNotification: ', postPgNotification)

        // update stored session
        let newSessionData = session;
        newSessionData.data.session.userPrivate.articles_liked = updated_userprivate_articles_liked;
        await updateSession(newSessionData);

        return likesData;

      } else {
        console.log('already liked')
        return 'already liked'
      }
    } catch (error) {
      console.error('handleArticleLike error:', error);
      return { error: error.message };
    }
  }

  useEffect(() => {
    if (session?.data?.session?.userPrivate) {
      fetchNotifications();
      setArticles_liked(session.data.session.userPrivate.articles_liked || []);
    } else {
      console.log('User not signed in or session not available');
    }
    fetchArticles();
  }, []); // Removed `session` to avoid repeated calls

  console.log('FeedView after useEffect: ', articles)

  //JSX function Returns 'More - Paid' if value == true or 'More - False' if value == false or null
  const isMoreFree = (isPaid) => (isPaid ? 'More - $0.50' : 'More - Free!');

  //JSX function Returns true if post.id is in useState articles_liked, false otherwise
  const isLiked = (postId) => {
    return session?.data?.session?.userPrivate?.articles_liked?.includes(postId) || false;
  };
  

  // For media queries under 900px viewport
  const viewport = useMediaQuery('(min-width: 680px)');

  function CommentCard(comment) {
    console.log('CommentCard: ', comments)
    console.log('CommentCard: ', comment) // undefined
    return (
      <>
        <Card sx={{ height: '100%', marginBottom: '1rem', marginTop: '1rem', borderBottom: '1px solid white' }}>
          <CardHeader
            avatar={
              <Avatar src={comment.author_profile_pic_url} alt={comment.author_name} sx={{ width: '40px', height: '40px' }}></Avatar>
            }
            title={
              <Typography varient='body1'><a href={`/user/${comment.author_id}`}> By {comment.author_name}</a></Typography>
            }
            subheader={parseTimestamp(comment.created)}
          />
          <CardContent>
            <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph>
              {comment.text}
            </Typography>
            {/* ToDo: icons, liking comments */}
          </CardContent>
        </Card>
        {comment.children.length > 0 && (
          <ul>
            {comment.children.map(c => (
              <CommentCard comment={c} key={c.comment_id} />
            ))}
          </ul>
        )}
      </>
    )
  }

  // TODO: can this be export default in its own components/CommentForm.jsx file?
  function CommentForm(post) { // https://react.dev/reference/react-dom/components/textarea#reading-the-text-area-value-when-submitting-a-form
    async function handleSubmit(e) {
      // Prevent the browser from reloading the page
      e.preventDefault();
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);
      const formJson = Object.fromEntries(formData.entries());
      console.log(formJson);

      // post comment to table comments
      const commentRequestBody = {
        text: formJson.commentContent,
        author_uid: session.data.session.userPublic.id,
        parent_article: comments_for_article, // stateVar of article showing comments
        parent_comment: null, // TODO
        num_likes: 0, // start with 0; TODO: make this a default in backend
        users_liking: [], // start with []; TODO: make this a default in backend
        author_profile_pic_url: session.data.session.userPublic.profile_pic_url,
        author_name: session.data.session.userPublic.name,
        num_reports: 0, // start with 0; TODO: make this a default in backend
        users_reporting: [], // start with []; TODO: make this a default in backend
        article_author_reported: false, // start with false; TODO: make this a default in backend
        author_id: session.data.session.userPublic.id, // TODO: duplicate, remove
        children: [] // start with []; TODO: make this a default in backend
      }
      // add the comment to the comment table
      const { data: commentData, error: commentError } = await postPgData('/comment', commentRequestBody, session);

      if (commentError) {
        console.error('Error:', commentError);
        console.log(commentError.message);
        return { error: commentError.message };
      }

      console.log('postPgData - comment:', commentData);
      // notify author that someone commented on their article
      console.log('postPgData - notifications:');
      const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
        `/notifications`,
        {
          trigger_uid: session.data.session.userPublic.id,
          trigger_uname: session.data.session.userPublic.name,
          action: 'commented on', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
          subject_aid_uid: post.id,
          subject_title_name: post.title, // had to add this as a input to handleArticleLike() function
          owner_uid: post.user_author // had to add this as a input to handleArticleLike() function
        },
        session
      );

      if (postPgNotificationError) {
        console.error('Error:', postPgNotificationError);
        console.log(postPgNotificationError.message);
        return { error: postPgNotificationError.message };
      }

      console.log('postPgNotification: ', postPgNotification)

      // ToDo: use params to reload page with comment posted & article at the top?
      // get the new list of comments
      fetchComments(comments_for_article)
      // todo: close the text entry box, or at least blank it out
    }
    const opened_article = (post.id == content_for_article) // opened_article true when 'more' clicked, else false
    const isEmpty = (post.content == "") // isEmpty true when content empty, else false
    return (
      (opened_article || isEmpty) ? ( // If you're bought the article, or if its content is empty, because we don't display the 'see more' button for empty content articles
        <>
          {
            comments ? comments.map(comment =>
            (
              CommentCard(comment)
            )
            ) : <h2>no comments yet</h2>
          }

          <form method="post" onSubmit={handleSubmit}>
            <label htmlFor={commentTextAreaId}></label>
            {/* 2000 character limit for comments, 1800 chars is a normal double space page and I'm concerned about comments much longer than that */}
            <textarea
              id={commentTextAreaId}
              name="commentContent"
              rows={2}
              maxLength={2000}
              sx={{ width: '70%', paddingBottom: '2rem', paddingTop: '.5rem', paddingLeft: '.5rem', borderRadius: '3px', borderColor: '#0f0f0f' }}
            />
            <Button primary size='large' disabled={isLoading} type='submit'>{isLoading ? 'loading...' : 'Post Comment'}</Button>
          </form>
        </>
      ) : ( // article unopened
        <>
          {
            comments ? comments.map(comment =>
            (
              CommentCard(comment)
            )
            ) : <h2>no comments yet</h2>
          }
          <form method="post" onSubmit={handleSubmit}>
            <label htmlFor={commentTextAreaId}></label>
            {/* 2000 character limit for comments, 1800 chars is a normal double space page and I'm concerned about comments much longer than that */}
            <textarea
              id={commentTextAreaId}
              name="commentContent"
              rows={2}
              maxLength={2000}
              sx={{ width: '70%', paddingBottom: '2rem', paddingTop: '.5rem', paddingLeft: '.5rem', borderRadius: '3px', borderColor: '#0f0f0f' }}
            />
            <Button primary size='large' disabled={!opened_article} type='submit'>{isLoading ? 'loading...' : 'Must Open Article to Comment'}</Button>
          </form>
        </>
      )
    ); // end of return
  } // end of CommentForm(post);

  function articleContentComponent(post) {
    console.log('articleContentComponent: ', post);
    if (web_anon_article_purchase) return null;

    const content = post.content || '';  // Safeguard against null/undefined
    const htmlContent = content ? mdParser.render(content) : '';

    return (
      <Card sx={{ height: '100%', marginBottom: '1rem', borderBottom: '1px solid white' }}>
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </CardContent>
      </Card>
    );
  }


  const renderMediaContent = (post) => {
    // todo: can these redundant checks be condensed as if (!post.image_url) return null;?
    if (post.image_url == "") {
      return (<></>)
    } else if (post.image_url == null) {
      return (<></>)
    }
    if (post.image_url !== "") {
      if (post.image_url.match(/\.(jpeg|jpg|gif|png|bmp|svg|JPEG|JPG|GIF|PNG|BMP|SVG)$/i)) {
        return (
          <CardMedia
            component="img"
            sx={{
              width: '95%',
              margin: '0 auto',
              maxWidth: '750px',
              maxHeight: '950px',
            }}
            image={post.image_url}
            title={post.author_name}
          />
        );
      } else if (post.image_url.match(/\.(webm|mp4|avi|quicktime|x-matroska|x-ms-wmv)$/i)) {
        let type = '';
        if (post.image_url.match(/\.(webm)$/i)) {
          type = 'video/webm';
        } else if (post.image_url.match(/\.(mp4)$/i)) {
          type = 'video/mp4';
        } else if (post.image_url.match(/\.(avi)$/i)) {
          type = 'video/avi';
        } else if (post.image_url.match(/\.(quicktime)$/i)) {
          type = 'video/quicktime';
        } else if (post.image_url.match(/\.(x-matroska)$/i)) {
          type = 'video/x-matroska';
        } else if (post.image_url.match(/\.(x-ms-wmv)$/i)) {
          type = 'video/x-ms-wmv';
        }

        return (
          <CardMedia
            component="video"
            controls
            sx={{
              width: '95%',
              margin: '0 auto',
              maxWidth: '750px',
              maxHeight: '950px',
            }}
            src={post.image_url}
            title={post.author_name}
            type={type}
          />
        );
      }
    }
    return null;
  };

  const createHandleReadArticle = (post) => () => readArticle(post, session);
  const createHandleTipAuthor = (post) => () => tipAuthor(post, session);

  return ( // Start Return for FeedView.js
    // Main Feed
    <Container 
    sx={{ 
      pt: 8, 
      color: 'white', 
      maxWidth: 'md', 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 4 // Ensures space between each card
    }}
  >
    {articles.length > 0 && articles.map(post => {
      const handleReadArticle = createHandleReadArticle(post);
      const handleTipAuthor = createHandleTipAuthor(post);

      return (
        <Card 
          key={post.id} 
          sx={{ 
            marginBottom: '2rem', // Ensures bottom spacing
            borderBottom: 1, 
            borderColor: 'white',
            padding: 2,
            maxWidth: '800px', 
            margin: 'auto' 
          }}
        >
          <CardHeader
            avatar={
              <Avatar src={post.author_profile_picture} sx={{ width: 40, height: 40 }} />
            }
            title={
              <Typography variant="body1">
                <a href={`/user/${post.user_author}`}>By {post.author_name}</a>
              </Typography>
            }
            subheader={parseTimestamp(post.created)}
          />

          <CardContent>
            <Typography variant={viewport ? 'h4' : 'h5'} sx={{ fontWeight: 600 }} gutterBottom>
              {post.title}
            </Typography>
          </CardContent>

          {renderMediaContent(post)}

          <CardContent>
            <Typography variant={viewport ? 'h6' : 'subtitle1'} paragraph sx={{ whiteSpace: 'pre-line' }}>
              <div dangerouslySetInnerHTML={{ __html: parseBlurb(post.blurb) }} />
            </Typography>

            {post.id === content_for_article && (
              <Box sx={{ display: 'inline' }}>
                {articleContentComponent(post)}
              </Box>
            )}

            {/* Buttons + Icons Container */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>

              {/* More and Tip Buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {post.content !== "" ? (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="large" 
                    onClick={handleReadArticle}
                    sx={{ 
                      minWidth: 150,  
                      color: 'white',  
                      textTransform: 'none',
                    }}
                  >
                    {isMoreFree(post.is_paid)} 
                  </Button>
                ) : null}

                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  onClick={handleTipAuthor}
                  sx={{ 
                    minWidth: 150,   
                    color: 'white',  
                    textTransform: 'none',
                  }}
                >
                  Tip $0.50
                </Button>
              </Box>

              {/* Icons Section */}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <IconButton onClick={() => handleArticleLike(post.id, post.title, post.user_author)}>
                  {isLiked(post.id) ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                </IconButton>

                <IconButton onClick={() => fetchComments(post.id)}>
                  <CommentIcon /> {post.num_comments}
                </IconButton>

                <IconButton onClick={() => session ? navigate(`/report/article/${post.id}`) : handleError(post.id, 'Are you logged in?')}>
                  <ReportProblemIcon />
                </IconButton>

                <IconButton onClick={() => handleError(post.id, `https://www.singlepaynews.com/${post.id}`)}>
                  <ShareIcon />
                </IconButton>
              </Box>

            </Box>

            {/* Error & Success Messages */}
            {errorMessage && post.id === errorPostId && (
              <Box sx={{ backgroundColor: '#ffdddd', padding: 1, borderRadius: 1, marginTop: 2 }}>
                <Typography color="error">{errorMessage}</Typography>
                <Button onClick={resetError} size="small">Close</Button>
              </Box>
            )}

            {successMessage && (
              <Box sx={{ backgroundColor: '#ddffdd', padding: 1, borderRadius: 1, marginTop: 2 }}>
                <Typography color="success">{successMessage}</Typography>
                <Button onClick={resetSuccess} size="small">Close</Button>
              </Box>
            )}

            {/* Comments Section */}
            <Box sx={{ marginTop: 2 }}>
              {(comments && post.id === comments_for_article) ? CommentForm(post) : null}
            </Box>
          </CardContent>
        </Card>
      );
    })}
  </Container>

  ); // End Return
} // End of FeedView


// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------


/** Avoid Infinite Loops
 * 
To avoid infinite re-rendering loops, ensure that state updates or context changes 
are only applied when necessary. Since updating session in OutletContext triggers 
a re-render, avoid making redundant updates. The balance update should only happen 
once when purchasing the article, and updateSession handles this.

This approach will allow you to update the session (including balance) immediately 
after an article purchase and reflect it in the UI without logging out and back in.
 */


// Your code looks solid and well-structured overall! Here are a few suggestions to further clean up and tidy it:

// ### 1. **Use `null` checks more concisely**
//    Instead of repeatedly checking for empty or `null` values for things like `post.image_url`, you could shorten it with a simple truthy check:
   
//    ```js
//    if (!post.image_url) {
//      return null;
//    }
//    ```

// ### 2. **Handle Errors in a Centralized Way**
//    You're handling errors in multiple places using `console.error`. You could create a central error-handling function and reuse it throughout to avoid repetition.

//    ```js
//    const handleFetchError = (error, message) => {
//      console.error(message, error);
//      setErrorMessage(message);
//    };
//    ```

//    Then use it like this:
//    ```js
//    try {
//      // fetch logic
//    } catch (error) {
//      handleFetchError(error, 'Failed to load articles.');
//    }
//    ```

// ### 3. **Simplify `useEffect` for better readability**
//    You can combine your condition inside the `useEffect` block:
//    ```js
//    useEffect(() => {
//      if (session?.data?.session?.userPrivate) {
//        fetchNotifications();
//        setArticles_liked(session.data.session.userPrivate.articles_liked || []);
//      }
//      fetchArticles();
//    }, [session]);
//    ```

// ### 4. **Optimize Event Handler Functions**
//    Some event handlers can be simplified to avoid unnecessary function creations within the JSX.

//    Example:
//    ```js
//    const handleLikeArticle = (id, title, authorId) => () => handleArticleLike(id, title, authorId);

//    // Later in JSX:
//    <IconButton onClick={handleLikeArticle(post.id, post.title, post.user_author)}>
//    ```

// ### 5. **Handle empty arrays properly**
//    When checking if an array is not empty, use `arr.length` directly to improve readability.

// ### 6. **Consistent logging**
//    Make sure all `console.log` outputs follow a consistent format. For example:

//    ```js
//    console.log('FeedView articles: ', articles);
//    ```

// ### Final Version with these suggestions:
// ```js
// const FeedView = () => {
//   // All state definitions remain the same
  
//   const handleFetchError = (error, message) => {
//     console.error(message, error);
//     setErrorMessage(message);
//   };

  // async function fetchArticles() {
  //   let arr = [];

  //   try {
  //     if (article) {
  //       // Case 1: article is defined
  //       const topArticle = await getPgData(`/articlefresh?id=eq.${article}`);
  //       if (topArticle.data?.[0]?.id) {
  //         arr.push(topArticle.data[0]);
  //       }

  //       const restOfArticles = await getPgData(`/articlefresh?limit=100&order=created.desc&id=not.in.(${article})`);
  //       if (Array.isArray(restOfArticles.data)) {
  //         const topPosted = await topPostDadsArticle(restOfArticles.data);
  //         setArticles(arr.concat(topPosted));
  //       } else {
  //         handleFetchError(null, 'restOfArticles data is not an array.');
  //       }
  //     } else {
  //       // Case 2: article is not defined
  //       const dadsArticle = await getPgData(`/articlefresh?id=eq.496`);
  //       if (dadsArticle.data?.[0]?.id) {
  //         arr.push(dadsArticle.data[0]);
  //       }

  //       const restOfArticles = await getPgData(`/articlefresh?limit=100&order=created.desc`);
  //       if (Array.isArray(restOfArticles.data)) {
  //         setArticles(arr.concat(restOfArticles.data));
  //       } else {
  //         handleFetchError(null, 'restOfArticles data is not an array.');
  //       }
  //     }
  //   } catch (error) {
  //     handleFetchError(error, 'Failed to load articles.');
  //   }
  // }

//   // Keep fetchNotifications and other async functions as is, but replace `console.error` with `handleFetchError`.

//   useEffect(() => {
//     if (session?.data?.session?.userPrivate) {
//       fetchNotifications();
//       setArticles_liked(session.data.session.userPrivate.articles_liked || []);
//     }
//     fetchArticles();
//   }, [session]);

//   // JSX remains the same with slight optimizations like:
  
//   const handleLikeArticle = (id, title, authorId) => () => handleArticleLike(id, title, authorId);

//   return (
//     <Container sx={{ pt: 8, color: 'white' }} maxWidth='md'>
//       {
//         articles.length > 0 && articles.map(post => {
//           const handleReadArticle = createHandleReadArticle(post);

//           return (
//             <Card key={post.id} sx={{ marginBottom: '2rem', borderBottom: '1px solid white' }}>
//               {/* Card Header */}
//               <CardHeader
//                 avatar={<Avatar src={post.author_profile_picture} sx={{ width: '40px', height: '40px' }} />}
//                 title={<Typography variant='body1'><a href={`/user/${post.user_author}`}>By {post.author_name}</a></Typography>}
//                 subheader={parseTimestamp(post.created)}
//               />

//               {/* Media Content */}
//               {renderMediaContent(post)}

//               <CardContent>
//                 <Typography variant={viewport ? 'h4' : 'h5'} sx={{ fontWeight: 600 }} gutterBottom>
//                   {post.title}
//                 </Typography>

//                 <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph style={{ whiteSpace: 'pre-line' }}>
//                   <div dangerouslySetInnerHTML={{ __html: parseBlurb(post.blurb) }} />
//                 </Typography>

//                 {post.id === content_for_article && (
//                   <Box sx={{ display: 'inline' }}>
//                     {articleContentComponent(post)}
//                   </Box>
//                 )}

//                 {post.content !== "" && (
//                   <Box sx={{ display: 'inline' }} onClick={handleReadArticle}>
//                     {isMoreFree(post.is_paid)}
//                   </Box>
//                 )}

//                 <Box sx={{ float: 'right' }}>
//                   <IconButton onClick={handleLikeArticle(post.id, post.title, post.user_author)}>
//                     {isLiked(post.id) ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
//                   </IconButton>
//                   <IconButton onClick={() => fetchComments(post.id)}>
//                     <CommentIcon />{post.num_comments}
//                   </IconButton>
//                   <IconButton onClick={() => session ? navigate(`/report/article/${post.id}`) : handleError('Are you logged in?')}>
//                     <ReportProblemIcon />
//                   </IconButton>
//                   <IconButton onClick={() => handleSuccess(`https://www.singlepaynews.com/${post.id}`)}>
//                     <ShareIcon />
//                   </IconButton>
//                 </Box>

//                 {errorMessage && (
//                   <div className="error-message">
//                     <p>{errorMessage}</p>
//                     <button onClick={resetError}>Close</button>
//                   </div>
//                 )}

//                 {successMessage && (
//                   <div className="success-message">
//                     <p>{successMessage}</p>
//                     <button onClick={resetSuccess}>Close</button>
//                   </div>
//                 )}

//                 <Box sx={{ display: 'inline' }}>
//                   {(comments && post.id === comments_for_article) ? CommentForm(post) : null}
//                 </Box>
//               </CardContent>
//             </Card>
//           );
//         })
//       }
//     </Container>
//   );
// };
// ```

// ### Summary of changes:
// - Simplified error handling.
// - Reduced redundant checks for `post.image_url`.
// - Moved event handlers like `handleLikeArticle` outside of JSX to avoid in-line function creation.
// - Centralized `useEffect` to handle session and articles more cleanly.

// These changes should improve readability and performance slightly. Let me know if you'd like any more tweaks!