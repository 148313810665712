import React, { useState } from 'react';
import { Form, Button, Container, Message, Icon, Checkbox, Header, Divider, Input, TextArea } from 'semantic-ui-react';
import {
  Avatar, Typography, Card, CardHeader,
  CardContent, CardMedia, IconButton, Box, useMediaQuery
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'; // todo: change thumbup icon state when article liked
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { postPgData, patchPgData } from '../data/rdsClient';
import { useOutletContext } from 'react-router';
//https://github.com/HarryChen0506/react-markdown-editor-lite
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
// Import a markdown parser (Markdown-it is a popular choice)
import MarkdownIt from "markdown-it";
import markdownItMark from 'markdown-it-mark'; // underlining isn't a standard markdown feature
const mdParser = new MarkdownIt({
  html: true, // Enable HTML tags in source
  linkify: true, // Autoconvert URL-like text to links
  typographer: true, // Enable some nice transformations (like (c) -> ©)
}).use(markdownItMark);

export const CreateView = () => {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const [content, setContent] = useState('');//react-md-editor
  const [hashtags, setHashtags] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [blurb, setBlurb] = useState('');
  const [paypalAddress, setPaypalAddress] = useState('');
  const session = useOutletContext();
  const [showContentPreview, setShowContentPreview] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(false); // New safety toggle

  const handleToggleChange = () => {
    setIsToggleOn(!isToggleOn); // Toggle state
  };
  //-----------------------------------------------------------------------------
  //  These handlers are for message and error handling with semantic
  //    It would be cool to be able to dismiss these, but I can't figure it out
  //-----------------------------------------------------------------------------
  const handleDismissError = () => { setErrorMessage(''); };
  const handleDismissSuccess = () => { setSuccessMessage(''); };
  //-----------------------------------------------------------------------------

  const isMoreFree = (value) => value ? <Button size='large'>More - $0.50</Button> : <Button size='large'>More - Free!</Button>

  function parse_hashtags(text) {
    var regex = /#\w+(?=\s|$)/g;
    var hashtagsWithHash = text.match(regex) || [];
    var hashtags = hashtagsWithHash.map(hashtagWithHash => hashtagWithHash.substring(1));
    return hashtags.slice(0, 5);
  }

  const handleCheckboxChange = (session) => {
    setIsPaid(!isPaid);
  };

  const handleContentChange = ({ text }) => {
    setContent(text);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleBlurbChange = (e) => {
    const blurbValue = e.target.value;
    setBlurb(blurbValue);
    setHashtags(parse_hashtags(blurbValue));
  };

  const handlePaypalAddressChange = (e) => {
    setPaypalAddress(e.target.value);
  };

  const onSubmit = async (formData, session) => {
    console.log('CreateView onSubmit called');

    // Check if session is null and handle accordingly
    const userAuthor = session?.data?.user?.id || null;
    const authorName = session?.data?.user?.username || 'Someone';
    const profilePicUrl = session?.data?.session?.userPublic?.profile_pic_url || null; // Default profile pic '/default-profile-pic.png'

    const formDataEscape = {
      is_paid: JSON.parse(formData.get('is_paid')),
      title: formData.get('title'),
      content: content, // Use state for content
      blurb: formData.get('blurb'),
      user_author: userAuthor,
      image_url: '', // Initialize with an empty string
      location_id: JSON.parse(formData.get('location_id')),
      author_name: authorName,
      author_profile_picture: profilePicUrl,
      tag_names: parse_hashtags(formData.get('blurb')),
      paypal_address: formData.get('paypal_address') || null, // Handle PayPal if anonymous
    };

    try {
      // Post the article data
      const { data: articleData, error: articleError } = await postPgData('/articlefresh', formDataEscape, session);

      // Check if there was an error during the article creation
      if (articleError) {
        console.error('Error:', articleError);
        return { error: articleError.message };
      }

      // Ensure articleData is an array and has at least one element
      if (!articleData || !Array.isArray(articleData) || articleData.length === 0 || !articleData[0]) {
        console.error('Error: Invalid article data returned:', articleData);
        return { error: 'Failed to create article. Please try again.' };
      }

      const articleId = articleData[0].id;

      // Handle file upload if there's a file
      if (file) {
        const reqData = new FormData();
        reqData.append('file', file);

        const options = {
          method: 'POST',
          headers: {
            'filepath': `article_${articleId}_media_${file.name}`
          },
          body: reqData
        };

        // Try to upload the file
        const response = await fetch('https://singlepaynews.com/api/upload', options);

        if (!response.ok) {
          console.error('File upload failed:', response.statusText);
          return { error: 'File upload failed. Please try again.' };
        }

        // If file upload is successful, update the article with the image URL
        const fileurl = `https://spnimages.s3.us-west-2.amazonaws.com/article_${articleId}_media_${file.name}`;
        formDataEscape.image_url = fileurl;

        // Patch the article with the image URL
        const { data: articleUpdateData, error: articleUpdateError } = await patchPgData(`/articlefresh?id=eq.${articleId}`, formDataEscape, session);
        if (articleUpdateError) {
          console.error('Error updating article with image URL:', articleUpdateError);
          return { error: articleUpdateError.message };
        }
      }

      // Handle session logic only if the user is signed in
      if (session?.data?.session?.userPublic) {
        let updated_article_list = session.data.session.userPublic.articles_posted_for_sale || [];
        updated_article_list.push(articleId);

        const { data: userUpdateData, error: userUpdateError } = await patchPgData(
          `/userpublic?id=eq.${session.data.session.userPublic.id}`,
          { 'articles_posted_for_sale': updated_article_list },
          session
        );
        if (userUpdateError) {
          console.error('Error updating user article list:', userUpdateError);
          return { error: userUpdateError.message };
        }
        return { articleData: articleData, updated_userPublic: userUpdateData };
      } else {
        // Anonymous post flow
        return { articleData: articleData };
      }
    } catch (error) {
      console.error('onSubmit error:', error);
      return { error: error.message };
    }
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate file size if a file is provided
    if (file && file.size > 2000000) {
      setErrorMessage('File size must be under 2MB. Article not posted, refresh to try again.');
      return;
    }

    // Validate required fields
    if (!title.trim() && !blurb.trim() && !content.trim() && !file) {
      setErrorMessage('Are you trying to make an empty post?  Check your preview below and refresh to try again.');
      return;
    }
    // Don't allow null content
    if (!content.trim()) {
      setContent('');
    }

    try {
      const formData = new FormData(event.target);

      // Prepare formData and handle submission
      const result = await onSubmit(formData, session);

      if (result.error) {
        setErrorMessage('Something went wrong; refresh to try again, or email us at singlepaynews@gmail.com');
      } else {
        if (session?.data?.session?.userPublic) {
          const new_session = session;
          new_session.data.session.userPublic.articles_posted_for_sale = [...(new_session.data.session.userPublic.articles_posted_for_sale || []), result.articleData[0].id];
          session.login(new_session);
        }

        setSuccessMessage(
          <p>
            Article posted! Use this link to share: {' '}
            <a href={`https://www.singlepaynews.com/${result.articleData[0].id}`}>https://www.singlepaynews.com/{result.articleData[0].id}</a>
          </p>
        );
      }
    } catch (error) {
      setErrorMessage('Something went wrong; refresh to try again, or email us at singlepaynews@gmail.com');
      console.error('Something went wrong, refresh to try again:', error);
    }
  };


  //-----------------------------------------------------------------------------
  //  Functions for displaying article preview
  //-----------------------------------------------------------------------------
  function articleContentComponent(content) {
    console.log('articleContentComponent: ', content)
    return (
      <>
        <Card sx={{ height: '100%', marginBottom: '1rem', borderBottom: '1px solid white' }}>
          {/* no cardheader needed, already shown above*/}
          <CardContent>
            {/* <MDEditor.Markdown source={content} style={{ whiteSpace: 'pre-wrap' }} /> */}
            {/* <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph
              style={{ whiteSpace: 'pre-line' }}>
              {content}
            </Typography> */}
            <div dangerouslySetInnerHTML={{ __html: mdParser.render(content) }} />
          </CardContent>
        </Card>
      </>
    )
  }

  const renderMediaPreview = ({ file }) => {
    if (!file) {
      return <></>;
    }

    const fileURL = URL.createObjectURL(file);
    const fileType = file.type;

    if (fileType.match(/image\/(jpeg|jpg|gif|png|bmp|svg\+xml)/i)) {
      return (
        <CardMedia
          component="img"
          sx={{
            width: '95%',
            margin: '0 auto',
            maxWidth: '750px',
            maxHeight: '950px',
          }}
          image={fileURL}
        />
      );
    } else if (fileType.match(/video\/(webm|mp4|avi|quicktime|x-matroska|x-ms-wmv)/i)) {
      return (
        <CardMedia
          component="video"
          controls
          sx={{
            width: '95%',
            margin: '0 auto',
            maxWidth: '750px',
            maxHeight: '950px',
          }}
          src={fileURL}
          type={fileType}
        />
      );
    }

    // Handle other types of media if needed (e.g., audio, PDFs)
    return <p>Unsupported media type: {fileType}</p>;
  };

  function parseBlurb(blurb) {
    // Define a regular expression pattern to match hashtags
    // var regex = /#(\w+)\s/g;
    // match hashtags that end with a ' ' or with the end of the string:
    var regex = /#\w+(?=\s|$)/g;
    // Use the regular expression to find hashtags
    var hashtagsWithHash = blurb.match(regex) || [];
    // Trim the leading '#' from each hashtag
    var hashtags = hashtagsWithHash.map(hashtagWithHash => hashtagWithHash.substring(1));

    // Replace occurrences of the first 5 hashtags with links
    var parsedBlurb = blurb;
    hashtags.slice(0, 5).forEach(hashtag => {
      const hashtagLink = `<a href="/hashtag/${hashtag}">#${hashtag}</a>`;
      parsedBlurb = parsedBlurb.replace(new RegExp(`#${hashtag}\\b`, 'g'), hashtagLink);
    });

    // Return the parsed blurb
    return parsedBlurb;
  }
  // For media queries under 900px viewport
  const viewport = useMediaQuery('(min-width: 680px)');
  //-----------------------------------------------------------------------------


  return (
    <div style={{ color: 'black', backgroundColor: '#0f0f0f', padding: '30px 0px' }}>
      <h1 id='create-header'>Single Pay News</h1>
      <h3 id='create-subheader'>Create A Post</h3>

      {/* 
//-----------------------------------------------------------------------------
//  Article input form
//-----------------------------------------------------------------------------
*/}
      <Container text>
        <Form
          style={{ backgroundColor: '#fbfbfb', padding: '10px 10px', textAlign: 'center', borderRadius: '4px' }}
          method='post'
          action='/create'
          encType='multipart/form-data'
          onSubmit={handleFormSubmit}
        >
          <input name='session' value={JSON.stringify(session)} type='hidden'></input>

          <Header as='h2'>
            <Icon name='money bill alternate outline'></Icon>
            Sell Article 50¢?
          </Header>
          <Checkbox
            toggle
            name='is_paid'
            value={isPaid.toString()}
            size='massive'
            color='red'
            label='Sell article'
            checked={isPaid}
            onChange={() => handleCheckboxChange(session)}
          />

          {(isPaid && !session.data) ? ( // if isPaid AND not signed in, show field for paypal address
            <>
              <Header as='h2'>
                PayPal Account
              </Header>
              <p>Use your paypal email address--this is where money from sales will be sent.</p>
              <Input
                icon='dollar'
                iconPosition='left'
                placeholder='Paypal Email Address'
                name='paypal_address'
                type='text'
                size='large'
                onChange={handlePaypalAddressChange}
                value={paypalAddress}
              />
            </>
          ) : ( // if !isPaid or session.data, use hidden field to make paypal address null
            <>
              <Input type='hidden' value='null' name='paypal_address' />
            </>
          )}

          <Divider horizontal style={{ padding: '10px 0px' }}>
            <Header as='h2'>
              <Icon name='newspaper outline'></Icon>
              Editor
            </Header>
          </Divider>

          <Form.Field style={{ textAlign: 'left' }}>
            <label>Title:</label>
            <Input
              placeholder='Title'
              name='title'
              type='text'
              onChange={handleTitleChange}
              value={title}
            />
          </Form.Field>

          <Form.Field style={{ textAlign: 'left' }}>
            <label>Upload Image (optional):</label>
            <Input
              icon='file image outline'
              iconPosition='left'
              placeholder='Upload an Image'
              name='image'
              type='file'
              accept='image/*'
              size='large'
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Field>

          <Form.Field style={{ textAlign: 'left' }}>
            <label>Blurb (less than 280 characters, use '#' for hashtags):</label>
            <TextArea
              rows='2'
              placeholder='Blurb'
              name='blurb'
              type='text'
              onChange={handleBlurbChange}
              value={blurb}
            />
          </Form.Field>

          <Form.Field style={{ textAlign: 'left' }}>
            <label>Content:</label>
            {/* <TextArea
              rows='8'
              placeholder='Content'
              name='content'
              type='text'
              onChange={handleContentChange}
              value={content}
            /> */}
            {/* <div id="markdown-editor">
            <MDEditor 
              value={content} 
              onChange={(val) => setContent(val || "")} 
              preview="edit" // This disables the internal preview mode
            /> */}
            <div>
              <MarkdownEditor
                name='content'
                value={content}
                style={{ height: "200px" }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleContentChange}
                config={{
                  view: {
                    menu: true, // Show the toolbar menu
                    md: true, // Show the Markdown editor
                    html: false, // Hide the HTML preview
                  },
                  canView: {
                    menu: true,
                    md: true,
                    html: false,
                    fullScreen: false,
                    hideMenu: false
                  }
                }}
              />
            </div>
          </Form.Field>

          {successMessage && (
            <Message
              positive
              //onDismiss={handleDismissSuccess} It would be cool to be able to dismiss these, but I can't figure it out
              style={{ textAlign: 'center' }}
            >
              <Icon name='check circle outline'></Icon>
              {successMessage}
            </Message>
          )}

          {errorMessage && (
            <Message
              negative
              //onDismiss={handleDismissError} It would be cool to be able to dismiss these, but I can't figure it out
              style={{ textAlign: 'center' }}
            >
              <Icon name='warning sign'></Icon>
              {errorMessage}
            </Message>
          )}

          {!errorMessage && !successMessage && (
            <>
              {/* Centered container for inline layout */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                {/* Toggle with label above */}
                <div style={{ marginRight: '1rem' }}>
                  <label style={{ display: 'block', marginBottom: '0.5rem' }}>Safety Toggle</label>
                  <Checkbox
                    checked={isToggleOn}
                    onChange={() => setIsToggleOn(!isToggleOn)}
                    toggle
                    size='massive'
                  />
                </div>
                {/* Button */}
                <Button primary size='large' type='submit' disabled={!isToggleOn}>
                  Create Post
                </Button>
              </div>
            </>
          )}
        </Form>
      </Container>
      {/* 
//-----------------------------------------------------------------------------
*/}

      {/* 
//-----------------------------------------------------------------------------
//  Article preview
//----------------------------------------------------------------------------- 
*/}
      <Container text>
        <Divider horizontal style={{ padding: '10px 10px' }}>
          <Header as='h2' color='grey'>
            <Icon name='print'></Icon>
            Preview
          </Header>
        </Divider>
        <>
          <Card sx={{ marginBottom: '2rem', borderBottom: '1px solid white' }}>
            {/* <CardHeader
              avatar={
                <Avatar src={session.?????author_profile_picture} sx={{ width: '40px', height: '40px' }}></Avatar>
              }
              title={
                <Typography varient='body1'><a href={`/user/${post.user_author}`}> By {post.author_name}</a></Typography>
              }
              subheader={parseTimestamp(post.created)}
            /> */}
            <CardContent>
              <Typography variant={viewport ? 'h4' : 'h5'} sx={{ fontWeight: 600 }} gutterBottom>
                {title}
              </Typography>
            </CardContent>
            {file ? renderMediaPreview({ file }) : <></>}
            <CardContent>
              <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph style={{ whiteSpace: 'pre-line' }}>
                <div dangerouslySetInnerHTML={{ __html: parseBlurb(blurb) }} />
              </Typography>
              <Box sx={{ display: 'inline' }}>
                {/* either show articleContentComponent or empty div */}
                {(showContentPreview) ? articleContentComponent(content) : <></>}
              </Box>

              {(content !== "") ? ( // only show button if there's content to see
                <Box sx={{ display: 'inline' }} onClick={() => setShowContentPreview(!showContentPreview)}>
                  {isMoreFree(isPaid)}
                </Box>
              ) : (
                <></>
              )}
              <Box sx={{ float: 'right' }}>
                <IconButton>
                  <><ThumbUpIcon /></>
                </IconButton>

                <IconButton>
                  <CommentIcon />
                </IconButton>

                <IconButton>
                  <ReportProblemIcon />
                </IconButton>

                <IconButton>
                  <ShareIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </>
      </Container>
      {/* 
//-----------------------------------------------------------------------------
*/}
    </div>
  );
};

export default CreateView;
