// complete a paypal payment; add to the tokens.

import { useState, useEffect, useRef } from "react";
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea, Message} from 'semantic-ui-react';

import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getPgData, patchPgData, postPgData } from '../data/rdsClient';

import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtons,
} from "@paypal/react-paypal-js";

const SCRIPT_PROVIDER_OPTIONS = {
	clientId: "AXAfPQX0u6EE4C_GNEmBQfC-djR03rWq2Yw6Mq_Op_dkgnjYikJ0AtrIEAhIbj3Kfj7onD407v1GkWKI"
  , components: "buttons"
  , currency: "USD" 
  , 'disable-funding': 'paylater'
};

const CUSTOM_FIELD_STYLE = {"border":"1px solid #606060","boxShadow":"2px 2px 10px 2px rgba(0,0,0,0.1)"};
const INVALID_COLOR = {
	color: "#dc3545",
};

// This value is from the props in the UI
const style = {"layout":"vertical"};

function createOrder(quantity, handleError) {
    // replace this url with your server
    return fetch("/api/orders", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
            cart: [
                {
                    sku: "1blwyeo8",
                    quantity: quantity,
                },
            ],
        }),
    })
        //
    .then(response => response.ok?response.json():response.json().then(errorData => {
        handleError(`Error: ${errorData.error}`);
        return Promise.reject(new Error('HTTP error'));
    }))
    .then(order => {
        // Your code here after creating the order
        console.log('.then order', order)
        console.log('.then order.id', order.id)
        return order.id;
    })
    .catch(error => {
        console.error("An error occurred while creating the order:", error);
        handleError("An error occurred while creating the order. Please refresh and try again.");
        return null;
    });
}


function onApprove(data, session, navigate, handleError, handleSuccess) {
    // replace this url with your server
    return fetch("/api/capture", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            orderID: data.orderID,
        }),
    })
    .then((response) => response.json())
    .then(async (orderData) => {
        // Your code here after capturing the order
        console.log('onApprove session:', session);
        // You can use the 'session' variable here as needed

            // add transaction to token_purchases for internal accounting
        // identifying information:
        const payer_email = orderData.payer.email_address
        const payer_firstname = orderData.payer.name.given_name
        const payer_lastname = orderData.payer.name.surname
        const payer_paypal_id = orderData.payer.payer_id
        const payment_source_paypal_account_id = orderData.payment_source.paypal.account_id
        const payment_source_paypal_account_status = orderData.payment_source.paypal.account_status
        const payment_source_email_address = orderData.payment_source.paypal.email_address
        const payment_source_firstname = orderData.payment_source.paypal.name.given_name
        const payment_source_lastname = orderData.payment_source.paypal.name.surname
            // capture information
        const payment_capture_id = orderData.purchase_units[0].payments.captures[0].id
        const payment_capture_amount = orderData.purchase_units[0].payments.captures[0].amount
        const payment_capture_create_time = orderData.purchase_units[0].payments.captures[0].create_time
        const payment_capture_dispute_categories = orderData.purchase_units[0].payments.captures[0].seller_protection.dispute_categories
        const payment_capture_dispute_status = orderData.purchase_units[0].payments.captures[0].seller_protection.status
            // seller_receivable information
        const payment_capture_seller_receivable_breakdown_gross_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.gross_amount
        const payment_capture_seller_receivable_breakdown_net_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.net_amount
        const payment_capture_seller_receivable_breakdown_paypal_fee = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee
        const payment_capture_seller_receivable_breakdown_status = orderData.purchase_units[0].payments.captures[0].status
        const payment_capture_seller_receivable_breakdown_update_time = orderData.purchase_units[0].payments.captures[0].update_time
            // 1) record transaction to internal accounts
        const payment_capture_seller_receivable_breakdown_gross_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_gross_amount.value);
        const payment_capture_seller_receivable_breakdown_net_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_net_amount.value);
        const payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_paypal_fee.value);
        console.log('floor(net*100): ', Math.floor(payment_capture_seller_receivable_breakdown_net_amount_value_as_float*100))
        const tokens_added = Math.floor(payment_capture_seller_receivable_breakdown_net_amount_value_as_float*100) - 100;
        // Removed math.round().  Said 8 for purchase of 816 tokens.
        const calculated_net_amount_to_tokens = tokens_added/100 // tokens as pennies
        const tokenPurchase_formDataEscape = { 
            //internal_transaction_id: default,
            external_transaction_id: payment_capture_id,
            total_amount: payment_capture_seller_receivable_breakdown_gross_amount.value, //Why did it charge $10 and not $20?
            net_amount_to_tokens: calculated_net_amount_to_tokens, // paypal net minus $1.00 spn fee
            external_fee: payment_capture_seller_receivable_breakdown_paypal_fee.value,
            spn_fee: "1.00", 
            user_id: session.data.session.userPublic.id, // user does exist
            time: 'NOW()',
            user_email: payer_email,
            user_firstname: payer_firstname,
            user_lastname: payer_lastname,
            user_paypal_id: payer_paypal_id,
            tokens_added: tokens_added
            };
        console.log('payment_capture_seller_receivable_breakdown_gross_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_gross_amount_value_as_float);
        console.log('payment_capture_seller_receivable_breakdown_net_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_net_amount_value_as_float);
        console.log('payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float: ', payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float);
        console.log('tokenPurchase_formDataEscape: ', tokenPurchase_formDataEscape);
        
        try {
            // insert purchase to token_purchases table
            const postPgDataResponse = await postPgData(
                '/token_purchases',
                tokenPurchase_formDataEscape,
                session // todo: validate that this works with the stored session
                )
            console.log('postPgDataResponse:', postPgDataResponse);
            // TODO: Figure out how to use handleSuccess here.  There's a data param for onApprove that's gotta be sourced.
        } catch (error) {
            console.log(error)  
            throw error;
        }
        // get userPrivate data
        // todo: I should be able to remove this; userPrivate is already in session.data.session.userprivate
        // but I want to be sure we have the most recent values b/c if you buy tokens and come here etc.
        let userPrivate = null;
        try {
          const userPrivateResponse = await getPgData(`/userprivate?id=eq.${session.data.session.userPublic.id}`, {}, session);
          if (userPrivateResponse.data[0]) {
            console.log('userPrivateResponse[0]==', userPrivateResponse.data[0]);
            userPrivate = userPrivateResponse.data[0]; // set userPrivateResponse[0] to a const for easy access
          } else if (userPrivateResponse.error) {
            console.error('Response data is not an array:', await userPrivateResponse);
          }else{
            console.log(await userPrivateResponse);
          }
        } catch (error) {
          console.log(error);
          throw error;
        }
        console.log('userPrivate.deposit_ids: ', userPrivate.deposit_ids)
        if (userPrivate.deposit_ids == null){  // change to falsy instead of null literal
            // deposit_ids is null on their first purchase.
            console.log('userPrivate.deposit_ids explicitly null')
            userPrivate.deposit_ids = [];
        }
        // breakout patch() variables for clarity
        userPrivate.deposit_ids.push(orderData.id); // PayPal orderID
        const updated_balance = userPrivate.balance + tokens_added; // 1 token = 1 penny
        
        const patchData = {
            balance: updated_balance,
            articles_bought: userPrivate.articles_bought
        }
        console.log(patchData)
        // patchPgData()
        try{
            const addTokenResponse = await patchPgData(`/userprivate?id=eq.${session.data.session.userPublic.id}`, 
            patchData, session);
            if (addTokenResponse.data) { // sucessful patch
                // can't find this in console??
                console.log('readArticle(patchPgData(userPrivate()))==', addTokenResponse.data);
                // update stored session
                let newSessionData = session;
                newSessionData.data.session.userPrivate = addTokenResponse.data[0];
                session.login(newSessionData);
                
                handleSuccess(`${tokens_added} Tokens Purchased! Total charge: ${tokenPurchase_formDataEscape.total_amount}, PayPal Fee: ${tokenPurchase_formDataEscape.external_fee}, SPN Fee: ${tokenPurchase_formDataEscape.spn_fee}`);
                navigate('/');
            }else if (addTokenResponse.error) { // error w/patch; do not show article
                console.error('Response data is not an array:', addTokenResponse.error);
                handleError('Something went wrong; email us at singlepaynews@gmail.com');
                navigate('/');
            }else{
                console.log(await addTokenResponse);
                handleError('Something went wrong; email us at singlepaynews@gmail.com');
                navigate('/');
            }
        } catch (error) {
            console.log(error);
            handleError(error);
            throw error;
        }
    });
}


// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, quantity, handleError, handleSuccess }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const session = useOutletContext(); // Capture session here
    const navigate = useNavigate(); // Capture navigate here

    const onApproveWrapper = (data) => {
        onApprove(data, session, navigate, handleError, handleSuccess); // Pass handleError and handleSuccess to onApprove
    };

    return (
        <>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
            <Container text>
                <Header as='p' inverted textAlign="center" dividing>Thank you for supporting local journalism</Header>
                <p textAlign="left" dividing>Click below to add funds to your account. As you know, this is not a subscription, you only pay for the articles you choose to buy, and SPN will never charge your credit card unless you chose to buy more Tokens.  For a more complete explanation our charges and our related policies, see below.</p>
                {/* <Header as='p' inverted textAlign="center" dividing>You will be charged $10.</Header> */}
                <PayPalButtons
                    style={style}
                    disabled={false}
                    forceReRender={[style, quantity]}
                    fundingSource={undefined} //TODO: only allow card payments  https://stackoverflow.com/questions/74823217/how-to-only-disable-paypal-button-but-enable-paylater-button  https://developer.paypal.com/docs/checkout/standard/customize/standalone-buttons/
                    createOrder={() => createOrder(quantity, handleError)}
                    onApprove={onApproveWrapper} // Use the wrapper function TODO: compare/contrast this with how we pass stuff to createOrder.  Can we pass handleError and handleSuccess this way?
                />
                
                <p textAlign="left" dividing>SPN does not charge for browsing the SPN website and viewing free content.  However, if you wish to buy articles listed for sale, you must fund your account.  This policy has several benefits to SPN, our journalists, and our Registered User community.</p>
                {/* <p textAlign="left" dividing>Requiring Users to fund an account before they can post on the SPN website ensures that the conversation on SPN is among people who share certain common values and objectives.  Specifically, SPN Registered Users support local journalism with their dollars and they have self-selected for certain common values. SPN’s Registered Users value evidence over ideology. They value truth over disinformation. And because they are willing to put their own name on their contributions to the conversation, they value accountability, which creates civility instead of conflict.</p> */}
                <p textAlign="left" dividing>Thank you for helping create an audience of serious and sober minded readers who buy the work of journalists selling their stories on SPN. Thank you for making the conversation on SPN engaging and for growing the audience for our journalists.  Thank you for keeping the conversation civil, easy to moderate, and fact based.</p>
                <p textAlign="left" dividing>By funding your account, you ensure that the Registered User community is made up of people who actually pay journalists for their work.  This is vitally important, as the main objective of SPN is to create an active and engaged community of Registered Users who financially support local journalism.</p>
                <br></br>
                <h2 textAlign="left" dividing>At the time of purchase, the cost of the bank's fees plus $1 is deducted to determine your Account Balance.</h2>
                <p textAlign="left" dividing>For example, if you fund your account with a $10.00 purchase using PayPal, the transaction fee to PayPal is $0.84, and your Account Balance will show 816 Tokens.</p>
                <p textAlign="left" dividing>In this example, From the $10.00 charge:<br></br>
                - $0.84 is deducted for PayPal’s transaction fee<br></br>
                - $1.00 is deducted to pay SPN.<br></br>
                - The remaining $8.16 is credited to your Account Balance as 816 Tokens.<br></br>
                </p>
                <p textAlign="left" dividing>Thereafter, each time you purchase an article, 50 Tokens are deducted from your Account Balance.  When your Account Balance is less than 50 Tokens, you won’t be able to buy more articles until and unless you replenish your Account Balance, but that is entirely up to you. This is not a subscription. SPN will never charge your credit card again unless you choose to buy more Tokens.</p>
                <p textAlign="left" dividing>Once you purchase Tokens and Tokens are credited to your account, the transaction is complete. The bank fee is paid to the credit card company or PayPal, $1 is paid to SPN, and the remainder is credited to your Account Balance as Tokens. Thereafter, the Tokens in your Account Balance are not refundable, not transferrable, and have no cash value. The Tokens can only be used to purchase Paid Posts on SPN. When Tokens are used to purchase Paid Posts, SPN receives a share of the purchase price. By purchasing Tokens, you agree to be bound by SPN’s terms of service, found on the “About” page on the SPN website. If you engage in conduct that causes you to be banned from SPN (according to our terms of service), you will also forfeit any Tokens in your Account Balance to SPN.</p>
            </Container>
            </div>
        </>
    );
}


//*/ end of Paypal functions & globals



export const BuyTokensView = () => {
    //Introduce State for purchase amount selection
  const [quantity, setQuantity] = useState(2);
    //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

    return (
        <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px' }}>
      <Container text>
        <h1 id='signup-header'>Support Local Journalism</h1>
        <h2 id='signup-subheader'>Buy Tokens</h2>
        <p id='signup-paragraph'>Single Pay News (SPN) is a platform where local journalists can earn a living by selling their work and building an audience that will last their entire career. The most straightforward way to support these journalists is to buy their work and follow them on SPN. Journalists sell their work on the SPN website for $0.50 per article. To support your favorite local journalists (and buy their articles), you need to have Tokens in your account.</p>
        <p id='signup-paragraph'>Click below to fund your account. This is not a subscription, you only pay for the articles you choose to buy, and SPN will never charge your credit card again (unless you choose to buy more Tokens). For a more complete explanation of these charges and our related policies, see below. Your full purchase price is converted into tokens as a token per penny, less $1.00 for SPN and PayPal's processing fees.</p>
        <div id='form-container'>
          <form onSubmit={(e) => e.preventDefault()}>
            <h2 id='form-header'>Purchase Tokens</h2>
            <br/>
            {/* Displaying $quantity */}
            <h2 id='form-header'>Your ${quantity} will support the newswriters you purchase from.</h2>
            <br/>
            {/* Buttons for changing $quantity */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <label style={{ color: 'white', marginBottom: '10px' }}>Select Quantity: </label>
                <div className="quantity-selector" style={{ display: 'flex', alignItems: 'center' }}>
                    <button disabled={quantity<3} style={{ marginRight: '10px' }} onClick={() => setQuantity(quantity - 1)}>-</button>
                    <h2 style={{ margin: '0 10px' }}>{quantity}</h2>
                    <button disabled={quantity>300} style={{ marginLeft: '10px' }} onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
            </div>
          </form>
        </div>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
            <div className="error-message">
                <p>{errorMessage}</p>
                <button onClick={resetError}>Close</button>
            </div>
        )}
        {/** Step 2: Displaying Success Messages in the UI */}
        {successMessage && (
            <div className="success-message">
                <p>{successMessage}</p>
                <button onClick={resetSuccess}>Close</button>
            </div>
        )}
        
        <div style={{ maxWidth: "750px", minHeight: "200px" }}>
            <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
                <ButtonWrapper showSpinner={false} quantity={quantity} handleError={handleError} handleSuccess={handleSuccess}/>
            </PayPalScriptProvider>
        </div>
      </Container>
    </div>
    );
};