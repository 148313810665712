// SignUpView.js
import {  useState, useEffect } from 'react';
import '../styles/LoginSignUpStyles/signup.css';
import { signupWithPg, postPgData } from '../data/rdsClient';
import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import { Container } from 'semantic-ui-react';

///* Paypal functions & globals
const SCRIPT_PROVIDER_OPTIONS = {
	clientId: "AXAfPQX0u6EE4C_GNEmBQfC-djR03rWq2Yw6Mq_Op_dkgnjYikJ0AtrIEAhIbj3Kfj7onD407v1GkWKI"
  , components: "buttons"
  , currency: "USD" 
  , 'disable-funding': ['paylater', 'applepay']
};
// This value is from the props in the UI
const style = {"layout":"vertical"};

async function createOrder(quantity, handleError) { 
    // because recaptcha is broken for CORS issues, we're removing it for now
    // we'll just process payments as needed
    return fetch("/api/orders", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            cart: [
                {
                    sku: "1blwyeo8",
                    quantity: quantity,
                },
            ],
        }),
    })
        //
        .then(response => response.ok?response.json():response.json().then(errorData => {
            handleError(`Error: ${errorData.error}`);
            return Promise.reject(new Error('HTTP error'));
        }))
        .then(order => {
            // Your code here after creating the order
            console.log('.then order', order)
            console.log('.then order.id', order.id)
            return order.id;
        })
        .catch(error => {
            console.error("An error occurred while creating the order:", error);
            handleError("An error occurred while creating the order. Please refresh and try again.");
            return null;
        });
    
}

function onApprove(data) {
    // replace this url with your server
    return fetch("/api/capture", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            orderID: data.orderID,
        }),
    })
        .then((response) => response.json())
        .then(async (orderData) => {
                // identifying information:
            const payer_email = orderData.payer.email_address
            const payer_firstname = orderData.payer.name.given_name
            const payer_lastname = orderData.payer.name.surname
            const payer_paypal_id = orderData.payer.payer_id
            const payment_source_paypal_account_id = orderData.payment_source.paypal.account_id
            const payment_source_paypal_account_status = orderData.payment_source.paypal.account_status
            const payment_source_email_address = orderData.payment_source.paypal.email_address
            const payment_source_firstname = orderData.payment_source.paypal.name.given_name
            const payment_source_lastname = orderData.payment_source.paypal.name.surname
                // capture information
            const payment_capture_id = orderData.purchase_units[0].payments.captures[0].id
            const payment_capture_amount = orderData.purchase_units[0].payments.captures[0].amount
            const payment_capture_create_time = orderData.purchase_units[0].payments.captures[0].create_time
            const payment_capture_dispute_categories = orderData.purchase_units[0].payments.captures[0].seller_protection.dispute_categories
            const payment_capture_dispute_status = orderData.purchase_units[0].payments.captures[0].seller_protection.status
                // seller_receivable information
            const payment_capture_seller_receivable_breakdown_gross_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.gross_amount
            const payment_capture_seller_receivable_breakdown_net_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.net_amount
            const payment_capture_seller_receivable_breakdown_paypal_fee = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee
            const payment_capture_seller_receivable_breakdown_status = orderData.purchase_units[0].payments.captures[0].status
            const payment_capture_seller_receivable_breakdown_update_time = orderData.purchase_units[0].payments.captures[0].update_time
            
                // 1) record transaction to internal accounts
            const payment_capture_seller_receivable_breakdown_gross_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_gross_amount.value);
            const payment_capture_seller_receivable_breakdown_net_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_net_amount.value);
            const payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_paypal_fee.value);
            const calculated_net_amount_to_tokens = payment_capture_seller_receivable_breakdown_net_amount_value_as_float - parseFloat('1.00');
            const tokens_added = Math.floor(calculated_net_amount_to_tokens * 100); // tokens as pennies
            const tokenPurchase_formDataEscape = { 
                //internal_transaction_id: default,
                external_transaction_id: payment_capture_id,
                total_amount: payment_capture_seller_receivable_breakdown_gross_amount.value, //Why did it charge $10 and not $20?
                net_amount_to_tokens: calculated_net_amount_to_tokens, // paypal net minus $1.00 spn fee
                external_fee: payment_capture_seller_receivable_breakdown_paypal_fee.value,
                spn_fee: "1.00", 
                user_id: null, // user doesn't exist yet
                time: 'NOW()',
                user_email: payer_email,
                user_firstname: payer_firstname,
                user_lastname: payer_lastname,
                user_paypal_id: payer_paypal_id,
                tokens_added: tokens_added
                };
            console.log('payment_capture_seller_receivable_breakdown_gross_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_gross_amount_value_as_float);
            console.log('payment_capture_seller_receivable_breakdown_net_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_net_amount_value_as_float);
            console.log('payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float: ', payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float);
            console.log('tokenPurchase_formDataEscape: ', tokenPurchase_formDataEscape);
            try {
                // insert to temp table basic_auth.unverified_users(email, pass, role, created, name, code)
                const postPgDataResponse = await postPgData(
                    '/token_purchases',
                    tokenPurchase_formDataEscape,
                    "" //validate that this can work with an empty token. -- yep, make insert allowed over spn.table for web_anon, see db_permissions
                    )
                    // rpc/signup either returns an object.token or nothing
                console.log('postPgDataResponse:', postPgDataResponse);
            } catch (error) {
                console.log(error)  
                throw error;
            }

                // TODO: Figure out how to use handleSuccess here.  There's a data param for onApprove that's gotta be sourced.
            window.alert(`${tokens_added} Tokens Purchased! Total charge: ${tokenPurchase_formDataEscape.total_amount}, PayPal Fee: ${tokenPurchase_formDataEscape.external_fee}, SPN Fee: ${tokenPurchase_formDataEscape.spn_fee}`);
                
            // 2) complete signup
            const formDataEscape = { // todo: security audit the random number generators (lol)
                email: payer_email.toLowerCase(),
                password: generate_OTP(), // 15 digit 0-9
                name: payer_firstname+' '+payer_lastname,
                code: generate_code(), // 6 digit 0-9
                tokens: tokens_added
              };
            try {
                // insert to temp table basic_auth.unverified_users(email, pass, role, created, name, code)
                // TODO: add amount of tokens purchased to signUp, it's not just 821 every time anymore
                const signupResponse = await signupWithPg( // returned undefined?
                    {
                        input_name: formDataEscape.name,
                        input_email: formDataEscape.email.toLowerCase(),
                        pass: formDataEscape.password,
                        input_code: formDataEscape.code,
                        tokens: tokens_added
                    }
                )
                // rpc/signup either returns an object.token or nothing
                console.log('signupResponse:', signupResponse);
                
                    // TODO: check if signupwithpg worked; don't send email it it didn't.

                const signupEmailData = {
                'input_name': formDataEscape.name,
                'input_email': formDataEscape.email.toLowerCase(),
                'input_code': formDataEscape.code,
                'initial_password': formDataEscape.password
                }
                console.log('signupEmailData: ',signupEmailData)
                //sendEmail(formDataEscape.email, formDataEscape.name, formDataEscape.code);
                const options = { //Default options are marked with *
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
                    headers: {
                        'Content-Type': "application/json"
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(signupEmailData) // body data type must match "Content-Type" header
                }
                const emailerResponse = await fetch('https://singlepaynews.com/api/emailer', options)
                console.log(emailerResponse)
                // TODO: check if email worked, handleError if not
                // TODO: Figure out how to use handleSuccess here.  There's a data param for onApprove that's gotta be sourced.
                window.alert('Check your email (and spam folder) for a link to set a password and register your account!')
            } catch (error) {
                console.log(error)  
                // TODO: Figure out how to use handleError here.  There's a data param for onApprove that's gotta be sourced.
                //handleError(error.message)
                throw error;
            }
        });
}

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, quantity, handleError, handleSuccess }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[style, quantity]} // turns out you do need to add quantity, otherwise it just always charges $2
                fundingSource={undefined} //TODO: only allow card payments  https://stackoverflow.com/questions/74823217/how-to-only-disable-paypal-button-but-enable-paylater-button  https://developer.paypal.com/docs/checkout/standard/customize/standalone-buttons/
                createOrder={() => createOrder(quantity, handleError)} // This should instead be a function reference, not an invocation.
                onApprove={onApprove} // what is data?  Does this still work? Nope. ::
                // [Error] Failed to load resource: the server responded with a status of 404 () (capture, line 0) https://singlepaynews.com/api/capture
                // Error: undefined is not an object (evaluating 'r.payer.email_address')
            />
        </>
    );
}
//*/ end of Paypal functions & globals

const generate_code = () => {
    const length = 6;
    let code = "";
    for(let i=0;i<length;i++){
        code += Math.floor(Math.random() * 10);
    }
    return code;
}

const generate_OTP = () => {
    const length = 15;
    let OTP = "";
    for(let i=0;i<length;i++){
        OTP += Math.floor(Math.random() * 10);
    }
    return OTP;
}


export const SignUpView = () => {
  console.log('SignUpView rendered');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it runs only once when component mounts


  const [quantity, setQuantity] = useState(10);

  // Step 1: Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};

  // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px' }}>
      <Container text>
        
        <h1 id='signup-header'>First Time?</h1>
        <p id='signup-paragraph'>To tip a journalist, you first have to set up an account. After you set up your account, be sure to go back to the article and click the "tip button" that brought you here. Otherwise, you won't leave them your tip.  Once your account is set up, you won't have to do this again to leave another tip, as long as you have tokens in your account.</p>

        <h2 id='signup-subheader'>Support Local Journalism</h2>
        <p id='signup-paragraph'>Single Pay News (SPN) is a platform where local journalists can earn a living by selling their work and building an audience that will last their entire career. The most straightforward way to support these journalists is to buy their work and follow them on SPN. Journalists sell their work on the SPN website for $0.50 per article, and accept $0.50 tips for free articles as well. To support your favorite local journalists (and buy their articles), you need to have Tokens in your account. You also have to buy Tokens to post your own articles and comments on SPN.</p>
        <p id='signup-paragraph'>Click below to fund your account. You pick the amount. You can buy from $3 to $20. This is not a subscription. Once you have bought Tokens, you will be able to post your own articles and comments. You will only pay for the articles you choose to buy, and SPN will never charge your credit card again (unless you choose to buy more Tokens, because you have bought so many articles that you ran out).</p>
        
        <p id='signup-paragraph'>All accounts begin with a purchase of between $3 and $20 of Tokens. Your full purchase price is then converted into tokens at the rate of one Token per penny, less $1.00 for SPN, and less the amount charged by PayPal for processing fees.</p>
        <h2 id='signup-subheader'>Your $10 will support the newswriters you purchase from.</h2>
        
        <div id='form-container'>
          <form onSubmit={(e) => e.preventDefault()}>
            <h2 id='form-header'>Your ${quantity} will support the newswriters you purchase from.</h2>
            {/* Buttons for changing $quantity */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <label style={{ color: 'white', marginBottom: '10px' }}>Select Quantity: </label>
                <div className="quantity-selector" style={{ display: 'flex', alignItems: 'center' }}>
                    <button disabled={quantity<3} style={{ marginRight: '10px' }} onClick={() => setQuantity(quantity - 1)}>-</button>
                    <h2 style={{ margin: '0 10px' }}>{quantity}</h2>
                    <button disabled={quantity>19} style={{ marginLeft: '10px' }} onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
            </div>
          </form>
        </div>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
            <div className="error-message">
                <p>{errorMessage}</p>
                <button onClick={resetError}>Close</button>
            </div>
        )}
        {/** Step 2: Displaying Success Messages in the UI */}
        {successMessage && (
            <div className="success-message">
                <p>{successMessage}</p>
                <button onClick={resetSuccess}>Close</button>
            </div>
        )}
        
        <div className="paypal-container" style={{ maxWidth: "750px", minHeight: "200px" }}>
        <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
            <ButtonWrapper showSpinner={false} quantity={quantity} handleError={handleError} handleSuccess={handleSuccess}/>
        </PayPalScriptProvider>
        </div>
      </Container>
    </div>
  );
};
